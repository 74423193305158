import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Row, Card, Col, Container, Button } from 'react-bootstrap';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

// Styles
import styles from '../../styles/components/Homepage/cards.module.scss';

export default function Cards() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulService {
        edges {
          node {
            slug
            title
            image {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            icon {
              fixed(width: 20, height: 20, quality: 100) {
                ...GatsbyContentfulFixed
              }
            }
            content {
              json
            }
            shortDescription {
              shortDescription
            }
            appointmentLink
            priority
          }
        }
      }
    }
  `);
  const sortedEdges = data.allContentfulService.edges.sort(
    (a, b) => a.node.priority - b.node.priority
  );

  return (
    <div className={styles.wrapper}>
      <Container className='my-5 py-md-5'>
        <div className='mt-5 text-center'>
          <h1 className=' text-uppercase'>
            <strong>Insurance Policies</strong>
          </h1>
          <p>Get the best insurance comparison across the market</p>
        </div>
        <Row xs={1} sm={2} md={3} className='g-5 mt-2 justify-content-center'>
          {sortedEdges.map(({ node }) => (
            <Col key={node.title} className={`d-table ${styles.cardWrapper}`}>
              <Card className={`d-table-cell ${styles.card}`}>
                <Link
                  to={
                    node.slug === 'sevenInOne-insurance'
                      ? '/sevenInOne'
                      : `/services/${node.slug}`
                  }
                  style={{ textDecoration: 'none' }}
                >
                  <div style={{ width: '100%', height: '300px' }}>
                    <Img
                      fluid={node.image.fluid}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                  <Card.Body className={`pt-5 ${styles.body}`}>
                    <Card.Title>
                      <div className='d-flex align-items-center mb-4'>
                        <Img
                          fixed={node.icon.fixed}
                          className={`${styles.img} me-3`}
                        />
                        <h3
                          className={`${styles.title} mb-0 d-flex align-items-end`}
                        >
                          <strong>{node.title}</strong>
                        </h3>
                      </div>
                    </Card.Title>
                    <Card.Text className={`small ${styles.text}`}>
                      {node.shortDescription.shortDescription}
                    </Card.Text>
                  </Card.Body>
                </Link>

                <Card.Footer className={`p-0 ${styles.footer}`}>
                  <Link to={`/quote/${node.slug}`}>
                    <Button
                      variant='card'
                      className='mt-auto'
                      style={{ width: '100%' }}
                    >
                      Get A Quote
                    </Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Quote from '../components/Quotations/travel'
import InsuranceProviders from "../components/insurance-providers";

const IndexPage = () => {
    return (
        <Layout>
            <SEO
                title="Quotation"
                article={false}
                pathname="/quote"
                description="Get an expert insurance quote in minutes! We'll help you find the best insurance at the lowest premium!"
            />
            <Quote />

            <InsuranceProviders />
        </Layout>
    );
};

export default IndexPage;

import React from "react";
import { Row, Col, Container, Accordion, Card, Button } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
import missionImg from "../../images/about/mission.svg";
import targetImg from "../../images/about/target.svg";

import styles from "../../styles/components/About/mission.module.scss";

export default function Mission() {
  return (
    <Container className="mt-5 pt-md-5 px-4">
      <Row className="my-5 justify-content-center py-md-5">
        <h1 className="text-center h1">
          <strong>Why Choose Asia Corp?</strong>
        </h1>
        <p className="text-center mb-md-5">
          With over 15 years of experience, we can assist you with any insurance
          requirement you may have.
        </p>

        <Row className="mt-5 mt-md-0 mb-5 justify-content-center">
          <Col md={6} className="d-flex align-items-center  mb-5 mb-md-0">
            <img src={targetImg} alt="" className={styles.icon} />
            <div>
              <h3>
                <strong>Mission</strong>
              </h3>
              <p className="m-0">
                To ensure that each client’s insurance requirements are provided
                with the best possible solution.
              </p>
            </div>
          </Col>
          <Col md={6} className="d-flex align-items-center  mb-5 mb-md-0">
            <img src={missionImg} alt="" className={styles.icon} />
            <div>
              <h3>
                <strong>Vision</strong>
              </h3>
              <p className="m-0">
                To be Sri Lanka’s most preferred insurance services aggregator.
              </p>
            </div>
          </Col>
        </Row>

        <Col md={6} className="mt-2">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  className="text-capitalize"
                >
                  <FaPlusCircle className="me-3" /> Not sure about your
                  insurance policy? We can help
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  We assist you with a comprehensive evaluation with the
                  existing insurance policies obtained from the market. (sum
                  insured, risk, benefits, clauses and conditions, excesses
                  against the paid premium) and assist you to select the most
                  beneficial product to you.
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="1"
                  className="text-capitalize"
                >
                  <FaPlusCircle className="me-3" />
                  Priority insurance requirements? We can give you peace of mind
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    We prepare all insurance comparisons (premiums, coverages,
                    and excess) for easy evaluation as well and release the
                    burden on your HR &amp; and finance team.
                  </p>
                  <p>
                    We manage All the information and documentation required on
                    your behalf and provide timely reports on your insurance
                    claims and premiums!
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="2"
                  className="text-capitalize text-start"
                >
                  <FaPlusCircle className="me-3" />
                  Compulsory insurance requirements
                  (Government/Financial/Diplomatic)
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Guiding you to fulfill all mandatory requirements and get
                    you the best deal in the process! We will make sure you
                    understand all the risks involved and help you find the best
                    insurance option for you.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>

        <Col md={6} className="mt-5 mt-lg-0 ">
          <ul className={styles.list}>
            <li className="h5">
              <FcCheckmark />
              Best Rates In The Market
            </li>
            <li className="h5">
              <FcCheckmark />
              Fast Claim Assistance
            </li>
            <li className="h5">
              <FcCheckmark />
              Updates On New Market Trends
            </li>
            <li className="h5">
              <FcCheckmark />
              Tailor-Made Policies
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

import React, { useState, useRef } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { navigate } from 'gatsby-link';

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        name,
        email,
        telephoneNumber: tel,
        message,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <Container className='my-5 py-5'>
      <Row className='g-5 '>
        <Col md={6}>
          <Form
            name='contact'
            method='post'
            action='/success/'
            data-netlify='true'
            data-netlify-honeypot='bot-field'
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type='hidden' name='form-name' value='appointment' />
            <label style={{ display: 'none' }}>
              Don’t fill this out: <input name='bot-field' />
            </label>

            <h1 className='mb-4'>Send A Message</h1>

            <Form.Group>
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                name='name'
                type='text'
                placeholder='Your Name'
                size='lg'
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    name='email'
                    type='email'
                    placeholder='Your Email'
                    size='lg'
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    name='telephoneNumber'
                    type='tel'
                    placeholder='Phone Number'
                    size='lg'
                    required
                    value={tel}
                    onChange={(e) => {
                      setTel(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control
                name='message'
                as='textarea'
                placeholder='Message'
                size='lg'
                style={{ height: '80px' }}
                value={message}
                required
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Form.Group>

            <Button className='mt-3' type='submit' style={{ width: '100%' }}>
              Submit
            </Button>
          </Form>
        </Col>

        <Col md={6}>
          <h1 className='mb-4'> Asia Corp Insurance Brokers (Pvt) Ltd.</h1>
          <p>15th Floor, East Tower, World Trade Center, Colombo 01</p>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31684.830974611366!2d79.82612709714508!3d6.937855879032204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2592655555555%3A0xa7bd3c85394bb7ad!2sASIA%20CORP%20HOLDINGS%20(PVT)%20LTD!5e0!3m2!1sen!2slk!4v1646901201837!5m2!1sen!2slk'
            width='600'
            height='250'
            style={{ border: 0, maxWidth: '100%' }}
            allowFullscreen=''
            loading='lazy'
            className='mb-3'
          ></iframe>
          <Col>
            <p className='mb-md-0'>
              <a href='mailto:info@acig.lk'>Email: info@acig.lk</a> <br />
              Hotline: +94 117 514 333
            </p>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

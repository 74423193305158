import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

import styles from "../../styles/components/About/ceo.module.scss";

export default function CEO() {
  const data = useStaticQuery(graphql`
    query {
      ceo: file(relativePath: { eq: "about/sunil-de-silva.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={styles.wrapper}>
      <Container className="my-5 py-md-5 px-4">
        <Row className="gx-5 justify-content-center align-items-center flex-column-reverse flex-md-row ">
          <Col md={6} className="px-md-5 mt-5 mt-md-0">
            <h1 className="mb-3 text-capitalize h1">
              <strong>CEO's Message </strong>
            </h1>

            <p className="fw-bold w-75">
              Coming together is beginning, keeping together is progress and
              working together is success!
            </p>
            <p>
              We firmly believe that the purpose of insurance is to provide
              protection for individuals, businesses and society as a whole. Our
              vision is to be Sri Lanka’s most preferred insurance services
              aggregator by assisting you with the best insurance team to
              support and guide you in getting the most competitive insurance
              product at the lowest premium with a comprehensive coverage. Our
              clients are our top priority and our employees are the heart of
              our existence.  Our goal is to ensure that each client’s insurance
              requirements are provided with the best possible solution by
              maintaining a position in the industry whilst ensuring a high
              level of service standards.
            </p>
            <p>
              We firmly believe that our employees are the heart of our
              existence.&nbsp; Our goal is to maintain a position in the
              industry whilst ensuring a high level of service standards.
            </p>
            <p>
              Our team understands your needs and requirements in every aspect.
            </p>
            <p className="fw-bold">
              Place your confidence in us and we will take care of the rest!
            </p>
            <p className={styles.sig}>Sunil De Silva</p>
          </Col>
          <Col
            md={6}
            className="px-md-5 d-flex justify-content-center align-items-center  "
          >
            <div className={`${styles.image} `}>
              <Img
                fluid={data.ceo.childImageSharp.fluid}
                objectFit="cover"
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";

import styles from "../styles/components/Homepage/partners.module.scss";

// React Multi Carousel Breakpoints
const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

export default function Partners() {
  const data = useStaticQuery(graphql`
    query {
      partner1: file(
        relativePath: { eq: "homepage/insurance-providers/softlogic-life.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner2: file(
        relativePath: { eq: "homepage/insurance-providers/aia.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner3: file(
        relativePath: { eq: "homepage/insurance-providers/lic.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner4: file(
        relativePath: { eq: "homepage/insurance-providers/janashakthi.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner5: file(
        relativePath: { eq: "homepage/insurance-providers/union-assurance.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner6: file(
        relativePath: { eq: "homepage/insurance-providers/allianz.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner7: file(
        relativePath: { eq: "homepage/insurance-providers/coop.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner8: file(
        relativePath: { eq: "homepage/insurance-providers/amana.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner9: file(
        relativePath: { eq: "homepage/insurance-providers/slic.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner10: file(
        relativePath: { eq: "homepage/insurance-providers/hnb.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner11: file(
        relativePath: { eq: "homepage/insurance-providers/continental.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner12: file(
        relativePath: { eq: "homepage/insurance-providers/lolc.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner13: file(
        relativePath: { eq: "homepage/insurance-providers/ceylinco.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner14: file(
        relativePath: { eq: "homepage/insurance-providers/orient.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner15: file(
        relativePath: { eq: "homepage/insurance-providers/mbsl.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner16: file(
        relativePath: { eq: "homepage/insurance-providers/sanasa.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner17: file(
        relativePath: { eq: "homepage/insurance-providers/fairfirst.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner18: file(
        relativePath: { eq: "homepage/insurance-providers/peoples.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner19: file(
        relativePath: { eq: "homepage/insurance-providers/arpico.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partner20: file(
        relativePath: { eq: "homepage/insurance-providers/nitf.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const partners = [
    { image: data.partner1.childImageSharp.fluid, name: "Softlogic Life" },
    { image: data.partner2.childImageSharp.fluid, name: "AIA Insurance" },
    { image: data.partner3.childImageSharp.fluid, name: "LIC" },
    {
      image: data.partner4.childImageSharp.fluid,
      name: "Janashakthi Insurance",
    },
    { image: data.partner5.childImageSharp.fluid, name: "Union Assurance" },
    { image: data.partner6.childImageSharp.fluid, name: "Allianz" },
    {
      image: data.partner7.childImageSharp.fluid,
      name: "Cooperative Insurance",
    },
    { image: data.partner8.childImageSharp.fluid, name: "Amana Takaful" },
    { image: data.partner9.childImageSharp.fluid, name: "SLIC" },
    { image: data.partner10.childImageSharp.fluid, name: "HNB" },
    {
      image: data.partner11.childImageSharp.fluid,
      name: "Containental Insurance",
    },
    { image: data.partner12.childImageSharp.fluid, name: "LOLC Insurance" },
    { image: data.partner13.childImageSharp.fluid, name: "Ceylinco Insurance" },
    { image: data.partner14.childImageSharp.fluid, name: "Orient Insurance" },
    { image: data.partner15.childImageSharp.fluid, name: "MBSL" },
    { image: data.partner16.childImageSharp.fluid, name: "Sanasa Insurance" },
    {
      image: data.partner17.childImageSharp.fluid,
      name: "Fairfirst Insurance",
    },
    { image: data.partner18.childImageSharp.fluid, name: "People's Insurance" },
    { image: data.partner19.childImageSharp.fluid, name: "Arpico Insurance" },
    { image: data.partner20.childImageSharp.fluid, name: "NITF" },
  ];

  return (
    <Container fluid className="text-center">
      <Row className={` my-5 justify-content-center gx-5`}>
        <Col className={styles.first}>
          <h5 className={`mb-5 `}>
            <strong>Insurance Providers</strong>
          </h5>
          <Row className="align-items-center">
            <Carousel
              sliderClass="align-items-center"
              autoPlay={true}
              autoPlaySpeed={2500}
              draggable={true}
              responsive={responsive}
              infinite={true}
              keyBoardControl={true}
              arrows={false}
              swipeable={true}
            >
              {partners.map((item) => (
                <Col className="align-items-center">
                  <Img
                    fluid={item.image}
                    objectFit="contain"
                    alt={item.name}
                    className="mx-5"
                    style={{
                      maxHeight: "65px",
                      maxWidth: "200px",
                      alignSelf: "center",
                    }}
                  />
                </Col>
              ))}
            </Carousel>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

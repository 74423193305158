import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as styles from '../styles/templates/service.module.scss';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import Img from 'gatsby-image';
// const { BLOCKS, MARKS } = require('@contentful/rich-text-types');

export const query = graphql`
  query($slug: String!) {
    contentfulNewsArticles(slug: { eq: $slug }) {
      title
      slug
      date(formatString: "ll")
      image {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      shortDescription {
        shortDescription
      }
      description {
        json
      }
    }
  }
`;

export default function Service({ data }) {
  const article = data.contentfulNewsArticles;
  const options = {
    renderNode: {
      'embedded-asset-block': (node) => {
        if (!node.data.target.fields) {
          return;
        }
        const alt = node.data.target.fields.title['en-US'];
        const url = node.data.target.fields.file['en-US'].url;
        return <img alt={alt} src={url} className='d-block my-5' />;
      },
      // paragraph: (node, children) => {
      //   return <p className={styles.paragraph}>{children}</p>;
      // },
      // text: (node) => {
      //   return node.value;
      // },
    },
  };

  return (
    <Layout>
      <SEO
        title='Asia Corp Insurance Brokers'
        article={false}
        pathname={`/news&events/${article.slug}`}
        to={`/news&events/${article.slug}`}
        description={article.shortDescription.shortDescription}
      />
      <Hero title={article.title} image={article.image.fluid} />

      <Container className='my-5 py-5'>
        <Row>
          <Col>
            <div className={`align-items-center ${styles.title}`}>
              <h1 className={`display-3 ${styles.title} mb-3`}>
                {article.title}
              </h1>
              <div>{article.date}</div>
              <div>
                <Img fluid={article.image.fluid} style={{ height: '100%' }} />
              </div>

              {/* +++++++++++++++++++++++ RICH TEXT +++++++++++++++++++++++++ */}
              <div className={`mt-5`}>
                {article.description.json.content.map((contentNode, index) => {
                  if (contentNode.nodeType === 'paragraph') {
                    const isBold = contentNode.content.some(
                      (textNode) =>
                        textNode.marks &&
                        textNode.marks.some((mark) => mark.type === 'bold')
                    );
                    const paragraphs = contentNode.content.map((textNode) =>
                      textNode.value.split('\r\n\r\n')
                    );
                    return paragraphs.flat().map((paragraph, index) => {
                      return (
                        <p
                          key={index}
                          className={isBold ? 'fw-bold my-3' : 'my-3'}
                        >
                          {paragraph}
                        </p>
                      );
                    });
                  }
                  return documentToReactComponents(contentNode, options);
                })}
              </div>

              {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
            </div>
          </Col>
        </Row>
        <hr className='my-5' />

        <Row>
          <Col>
            <div className={styles.social}>
              <a href='https://www.facebook.com/acig.lk/' target='_blank'>
                <FaFacebook />
              </a>
              <a href='https://www.instagram.com/acig.lk/' target='_blank'>
                <FaInstagram />
              </a>
              <a
                href='https://www.linkedin.com/company/asia-corp-insurance-group/'
                target='_blank'
              >
                <FaLinkedin />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

import React, { useState } from "react";
import Hero from "../../components/hero";
import SEO from "../../components/seo";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { navigate } from "gatsby-link";
import { useStaticQuery } from "gatsby";

// Form Helpers
function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default function Quote() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [houseValue, setHouseValue] = useState("");
    const [contentValue, setContentValue] = useState("");
    const [riskAddress, setRiskAddress] = useState("")



    const data = useStaticQuery(graphql`
        query {
        contentfulService (slug: {eq: "home-insurance"}) {
            slug
            title
            shortDescription {
            shortDescription
            }
            icon {
              fixed(width: 20, height: 20, quality: 100) {
                ...GatsbyContentfulFixed
              }
            }
            image {
                fluid(maxWidth: 1366, quality: 40) {
                ...GatsbyContentfulFluid
                }
            }
        }
    }
  `);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": "home-insurance-quote",
                name,
                email,
                telephoneNumber: tel,
                houseValue,
                contentValue,
                riskAddress,
            }),
        })
            .then(() => navigate(form.getAttribute("action")))
            .catch((error) => alert(error));
    };

    return (
        <>
            <SEO
                title={`${data.contentfulService.title} Quotation`}
                article={false}
                pathname={`/services/${data.contentfulService.slug}`}
                description={data.contentfulService.shortDescription.shortDescription}
            />
            <Hero
                title={data.contentfulService.title}
                subtitle="Get a Quick Quotation"
                image={data.contentfulService.image.fluid}
            />
            <Container className="my-5 py-5 px-5">

                <h3>Please fill in the form below for your {data.contentfulService.title.toLowerCase()} quotation.</h3>
                <Row className="g-5">
                    <Col md={12}>
                        <Form
                            name="home-insurance-quote"
                            method="post"
                            action="/success/"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={handleSubmit}
                        >
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input type="hidden" name="form-name" value="home-insurance-quote" />
                            <label style={{ display: "none" }}>
                                Don’t fill this out: <input name="bot-field" />
                            </label>

                            {/* Contact Details */}
                            <Row xs={1} >
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                name="name"
                                                type="text"
                                                placeholder="Full Name"
                                                size="lg"
                                                required
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                            />
                                        </Form.Group></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                placeholder="Your Email"
                                                size="lg"
                                                required
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                name="telephoneNumber"
                                                type="tel"
                                                placeholder="Mobile Number"
                                                size="lg"
                                                required

                                                value={tel}
                                                onChange={(e) => {
                                                    setTel(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>House Value</Form.Label>
                                            <Form.Control
                                                name="houseValue"
                                                type="number"
                                                placeholder="House Value"
                                                size="lg"
                                                required
                                                value={houseValue}
                                                onChange={(e) => {
                                                    setHouseValue(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Content Value</Form.Label>
                                            <Form.Control
                                                name="contentValue"
                                                type="number"
                                                placeholder="Content Value"
                                                size="lg"
                                                required
                                                value={contentValue}
                                                onChange={(e) => {
                                                    setContentValue(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Risk Address</Form.Label>
                                            <Form.Control
                                                name="riskAddress"
                                                type="text"
                                                placeholder="Risk Address"
                                                size="lg"
                                                required
                                                value={riskAddress}
                                                onChange={(e) => {
                                                    setRiskAddress(e.target.value);
                                                }}
                                            />
                                        </Form.Group></Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Button className="mt-4 mt-lg-5" type="submit" variant="primary2" style={{ width: "100%" }} >
                                            Submit
                                        </Button></Col>
                                </Row>
                            </Row>


                        </Form>
                    </Col>
                </Row>

            </Container >
        </ >
    );
}

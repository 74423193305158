import React, { useState } from 'react';
import Hero from '../../components/hero';
import SEO from '../../components/seo';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { navigate } from 'gatsby-link';

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function Quote() {
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [tel, setTel] = useState('');
  const [email, setEmail] = useState('');

  // Vehicle details
  const [year, setYear] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [marketValue, setMarketValue] = useState('');

  // House details
  const [houseValue, setHouseValue] = useState('');
  const [riskLocation, setRiskLocation] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'seven-in-one-quote',
        name,
        dob,
        telephoneNumber: tel,
        email,
        year,
        make,
        model,
        marketValue,
        houseValue,
        riskLocation,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <>
      <SEO
        title={` 7 in 1 Quotation`}
        article={false}
        pathname={`/services/sevenInOne-insurance`}
        description={`Seven in one insurance`}
      />
      <Hero
        title={`7 in 1 Insurance`}
        subtitle='Get a Quick Quotation'
        // image={data.contentfulService.image.fluid}
      />
      <Container className='my-5 py-5 px-5'>
        <h3>
          Please fill in the form below for your 7 in 1 insurance quotation.
        </h3>
        <Row className='g-5 '>
          <Col md={12}>
            <Form
              name='seven-in-one-quote'
              method='post'
              action='/success/'
              data-netlify='true'
              data-netlify-honeypot='bot-field'
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input
                type='hidden'
                name='form-name'
                value='seven-in-one-quote'
              />
              <label style={{ display: 'none' }}>
                Don’t fill this out: <input name='bot-field' />
              </label>

              {/* Contact Details */}

              <Row xs={1} className='mb-5'>
                <Row xs={1} md={2}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Name of the Proposer</Form.Label>
                      <Form.Control
                        name='name'
                        type='text'
                        placeholder='Full Name'
                        size='lg'
                        required
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        name='dob'
                        type='date'
                        placeholder='Date of Birth'
                        size='lg'
                        required
                        value={dob}
                        onChange={(e) => {
                          setDob(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row xs={1} md={2}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        name='email'
                        type='email'
                        placeholder='Your Email'
                        size='lg'
                        required
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        name='telephoneNumber'
                        type='tel'
                        placeholder='Contact Number'
                        size='lg'
                        required
                        value={tel}
                        onChange={(e) => {
                          setTel(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* 7in1 Insurance */}
                <Form.Label className='mt-5 mb-0'>
                  Details of the Vehicle
                </Form.Label>

                <Row xs={1} md={2}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Year</Form.Label>
                      <Form.Control
                        name='year'
                        type='number'
                        placeholder='Year'
                        size='lg'
                        required
                        value={year}
                        onChange={(e) => {
                          setYear(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Make</Form.Label>
                      <Form.Control
                        name='make'
                        type='text'
                        placeholder='Make'
                        size='lg'
                        required
                        value={make}
                        onChange={(e) => {
                          setMake(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row xs={1} md={2}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Model</Form.Label>
                      <Form.Control
                        name='model'
                        type='text'
                        placeholder='Model'
                        size='lg'
                        required
                        value={model}
                        onChange={(e) => {
                          setModel(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Vehicle Value (Value should be in line with the current
                        market price)
                      </Form.Label>
                      <Form.Control
                        name='marketValue'
                        type='number'
                        min={0}
                        placeholder='Market Value'
                        size='lg'
                        required
                        value={marketValue}
                        onChange={(e) => {
                          setMarketValue(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Label className='mt-5 mb-0'>
                  Details of the House
                </Form.Label>

                <Row xs={1} md={2}>
                  <Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>House Value</Form.Label>
                        <Form.Control
                          name='houseValue'
                          type='number'
                          min={0}
                          placeholder='House Value'
                          size='lg'
                          required
                          value={houseValue}
                          onChange={(e) => {
                            setHouseValue(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Risk Location</Form.Label>
                      <Form.Control
                        name='riskLocation'
                        type='text'
                        placeholder='Risk Location'
                        size='lg'
                        required
                        value={riskLocation}
                        onChange={(e) => {
                          setRiskLocation(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      className='mt-4 mt-lg-5'
                      type='submit'
                      variant='primary2'
                      style={{ width: '100%' }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

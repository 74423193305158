import * as React from "react";
import Layout from "../../components/layout";
import Quote from "../../components/Quotations/travel";
import InsuranceProviders from "../../components/insurance-providers";
import Partners from "../../components/insurance-providers";

const IndexPage = () => {
  return (
    <Layout>
      {/* <Quote />
      <Partners />
      <InsuranceProviders /> */}
    </Layout>
  );
};

export default IndexPage;

import React from 'react'
import { InlineWidget } from 'react-calendly'

export default function Calendly () {
  return (
    <>
      <div id='colombo' className='my-5'>
        <InlineWidget
          url='https://calendly.com/asiacorpinsurancebrokers'
          styles={{ height: '750px' }}
        />
      </div>
    </>
  )
}

import React from 'react';
import { Col, Button } from 'react-bootstrap';
import content from '../../content/insuranceCards';
import Jumbotron from '../Homepage/jumbotron';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FcApproval } from 'react-icons/fc';
import Img from 'gatsby-image';

import styles from '../../styles/components/Services/cards.module.scss';

export default function Cards() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulService {
        edges {
          node {
            slug
            title
            features
            image {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            icon {
              fixed(width: 20, height: 20, quality: 100) {
                ...GatsbyContentfulFixed
              }
            }
            shortDescription {
              shortDescription
            }
            appointmentLink
            priority
          }
        }
      }
    }
  `);

  const sortedEdges = data.allContentfulService.edges.sort(
    (a, b) => a.node.priority - b.node.priority
  );

  return (
    <div className={styles.wrapper}>
      {sortedEdges.map(({ node }, idx) => (
        <Jumbotron
          image={node.image.fluid}
          mb0={content.length - 1 === idx}
          position={idx % 2 > 0 ? 'left' : 'right'}
          bg={idx % 2 > 0 ? 'grey' : ''}
          variant={idx % 2 > 0 ? 'rhombus' : 'rhombusRight'}
          link={
            node.slug === 'sevenInOne-insurance'
              ? '/sevenInOne'
              : `/services/${node.slug}`
          }
          content={
            node.slug === 'sevenInOne-insurance' ? (
              <Col>
                <Link to={`/sevenInOne`}>
                  <div className={`d-flex align-items-center ${styles.title}`}>
                    <Img
                      fixed={node.icon.fixed}
                      className={`${styles.img} me-3`}
                    />
                    <h1 className='p-0 mb-0'>{node.title}</h1>
                  </div>
                </Link>
                <p>{node.shortDescription.shortDescription}</p>
                <ul className={styles.list}>
                  {node.features &&
                    node.features.map((item) => (
                      <li>
                        <FcApproval className='me-3' />
                        {item}
                      </li>
                    ))}
                </ul>
                <a href={`/quote/${node.slug}`}>
                  <Button
                    className='justify-self-end mt-3 me-3'
                    variant='tertiary3'
                    size='lg'
                  >
                    Get a Quote
                  </Button>
                </a>

                <Link to='/sevenInOne'>
                  <Button
                    className='justify-self-end mt-3'
                    variant='text'
                    size='lg'
                  >
                    View Policy
                  </Button>
                </Link>
              </Col>
            ) : (
              <Col>
                <Link to={`/services/${node.slug}`}>
                  <div className={`d-flex align-items-center ${styles.title}`}>
                    <Img
                      fixed={node.icon.fixed}
                      className={`${styles.img} me-3`}
                    />
                    <h1 className='p-0 mb-0'>{node.title}</h1>
                  </div>
                </Link>
                <p>{node.shortDescription.shortDescription}</p>
                <ul className={styles.list}>
                  {node.features &&
                    node.features.map((item) => (
                      <li>
                        <FcApproval className='me-3' />
                        {item}
                      </li>
                    ))}
                </ul>
                <a href={`/quote/${node.slug}`}>
                  <Button
                    className='justify-self-end mt-3 me-3'
                    variant='tertiary3'
                    size='lg'
                  >
                    Get a Quote
                  </Button>
                </a>

                <Link to='/appointment'>
                  <Button
                    className='justify-self-end mt-3'
                    variant='text'
                    size='lg'
                  >
                    Make an Appointment
                  </Button>
                </Link>
              </Col>
            )
          }
        />
      ))}
    </div>
  );
}

import React from 'react';
import { Link } from 'gatsby';
import Logo from '../utils/logo-horizontal';
import { Row, Col, Container } from 'react-bootstrap';
import nugo from '../images/nugo.svg';
import styles from '../styles/components/footer.module.scss';
import moment from 'moment';

const Footer = () => {
  return (
    <footer>
      <div className={styles.footer}>
        <Container className={`${styles.main} `}>
          <Row className='align-items-center'>
            <Col lg={6}>
              <div className={`${styles.logo} mb-4`}>
                <Logo />
              </div>
              <div className='mt-4'>
                <p>
                  Asia Corp Insurance Brokers (Pvt) Ltd is an established
                  organization in the industry with all requisites to secure the
                  insurance broker license from the Insurance Regulatory
                  Commission of Sri Lanka (IRCSL) and a member of the Sri Lanka
                  Insurance Brokers Association (SLIBA).
                </p>
                <p className='d-none d-md-block'>
                  © {moment().format('YYYY')} Asia Corp Insurance Brokers Pvt
                  Ltd. All Rights Reserved.
                </p>
              </div>
            </Col>
            <Col lg={2} className={`${styles.links} mt-5 mt-lg-0`}>
              <ul className='p-0 p-md-5'>
                <li>
                  <Link to='/'>Home</Link>
                </li>

                <li>
                  <a href='/about'>About</a>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/careers'>Careers</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
                <li>
                  <Link to='/appointment'>Appointment</Link>
                </li>
              </ul>
            </Col>
            <Col lg={4} className={`mt-5 mt-lg-0`}>
              <h2 className='mb-4'>Contact Details</h2>
              <h4>Asia Corp Insurance Brokers (Pvt) Ltd.</h4>
              <p>
                15th Floor, East Tower, World Trade Center, Colombo 01, Sri
                Lanka
              </p>
              <p className='mb-md-0'>
                Email: info@acig.lk <br />
                Hotline: +94 117 514 333
              </p>
            </Col>
          </Row>
        </Container>

        <Container className='d-lg-flex justify-content-end mt-5'>
          <Row>
            <p className='d-md-none mb-5'>
              © {moment().format('YYYY')} Asia Corp Insurance Brokers Pvt Ltd.
              All Rights Reserved.
            </p>
            <a
              href='https://www.nugo.lk'
              rel='noReferrer'
              target='_blank'
              className='mb-0'
            >
              <img src={nugo} alt='NUGO' className={styles.signature} />
            </a>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;

import * as React from "react";
import Header from "../components/header";
import { Button, Container } from "react-bootstrap";
import { Link } from "gatsby";

import Footer from "../components/footer";
import styles from "../styles/success.module.scss";

const IndexPage = () => {
  return (
    <div>
      <Header />
      <Container className={styles.container}>
        <div className={styles.wrapper}>
          <h1>Thank you</h1>
          <p>
            We have received your message and we will get in touch as soon as
            possible!
          </p>
        </div>
        <Link to="/">
          <Button variant="primary">Go Back</Button>
        </Link>
      </Container>
      <Footer />
    </div>
  );
};

export default IndexPage;

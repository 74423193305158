import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import styles from '../../styles/components/About/partners.module.scss';

export default function Partners() {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "partners/01.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "partners/02.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "partners/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "partners/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "partners/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6: file(relativePath: { eq: "partners/06.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image7: file(relativePath: { eq: "partners/07.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image8: file(relativePath: { eq: "partners/08.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const items = [
    {
      image: data.image1.childImageSharp.fluid,
      link: 'https://www.serendibhealthways.com/',
    },
    {
      image: data.image8.childImageSharp.fluid,
      link: 'https://idealmotors.lk',
    },
    {
      image: data.image2.childImageSharp.fluid,
      link: 'https://ktdoctor.com/',
    },
    {
      image: data.image3.childImageSharp.fluid,
      link: 'https://www.pediatricafterhour.com/',
    },
    {
      image: data.image5.childImageSharp.fluid,
      link: '',
    },
    {
      image: data.image4.childImageSharp.fluid,
      link: '',
    },
    {
      image: data.image6.childImageSharp.fluid,
      link: 'https://www.healthylife.lk/',
    },
    {
      image: data.image7.childImageSharp.fluid,
      link: 'https://www.ppmg.lk/',
    },
  ];
  return (
    <Container className='my-5 py-5 '>
      <Row className='gx-5 justify-content-center align-items-center'>
        <p className='text-center h3 mb-5'>
          <strong>Affiliated Group of Companies</strong>
        </p>
        <div className={`${styles.logos} mt-5`}>
          {items.map((item, idx) =>
            item.link !== '' ? (
              <a href={item.link} target='_blank' key={idx}>
                <Img
                  fluid={item.image}
                  className={styles.logo}
                  objectFit='contain'
                />
              </a>
            ) : (
              <Img
                fluid={item.image}
                key={idx}
                className={styles.logo}
                objectFit='contain'
              />
            )
          )}
        </div>
      </Row>
    </Container>
  );
}

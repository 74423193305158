import React, { useState } from 'react';
import Infobar from './infobar';
import NavDrawer from './navdrawer';
import Logo from '../utils/logo';
import { Button, Container, Dropdown, DropdownButton } from 'react-bootstrap';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';

import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';

import styles from '../styles/components/header.module.scss';

const Header = () => {
  const [showServices, setShowServices] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      allContentfulService {
        edges {
          node {
            title
            slug
            priority
          }
        }
      }
    }
  `);

  const sortedEdges = data.allContentfulService.edges.sort(
    (a, b) => a.node.priority - b.node.priority
  );
  return (
    <>
      <Infobar />
      <header className={styles.header}>
        <Container className={`${styles.navContent} `}>
          <div className={styles.logo}>
            <Link to='/'>
              <Logo />
            </Link>
          </div>
          <div className={`${styles.links}`}>
            <Link to='/' activeClassName={styles.active}>
              Home
            </Link>

            <Link to='/about' activeClassName={styles.active}>
              About
            </Link>

            <Link to='/services' className='mx-0'>
              <DropdownButton
                className='dropdown-button'
                variant=''
                title='Services'
                id='input-group-dropdown-1'
                show={showServices}
                onMouseEnter={() => {
                  setShowServices(true);
                }}
                onMouseLeave={() => {
                  setShowServices(false);
                }}
              >
                {sortedEdges.map(({ node }) => (
                  <Dropdown.Item
                    key={node.priority}
                    onClick={() => {
                      navigate(
                        node.slug === 'sevenInOne-insurance'
                          ? '/sevenInOne'
                          : `/services/${node.slug}`
                      );
                      setShowServices(false);
                    }}
                  >
                    {node.title}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Link>

            <Link to='/careers' activeClassName={styles.active}>
              Careers
            </Link>

            <Link
              style={{ whiteSpace: 'nowrap' }}
              to='/news&events'
              activeClassName={styles.active}
            >
              News & Events
            </Link>

            <Link to='/contact' activeClassName={styles.active}>
              Contact
            </Link>

            <Link to='/appointment'>
              <Button variant='tertiary'>Book Appointment</Button>
            </Link>
          </div>
          <div className={styles.social}>
            <a href='https://www.facebook.com/acig.lk/' target='_blank'>
              <FaFacebook />
            </a>
            <a href='https://www.instagram.com/acig.lk/' target='_blank'>
              <FaInstagram />
            </a>
            <a
              href='https://www.linkedin.com/company/asia-corp-insurance-group/'
              target='_blank'
            >
              <FaLinkedin />
            </a>
          </div>

          <div className={styles.navdrawer}>
            <NavDrawer />
          </div>
        </Container>
      </header>
    </>
  );
};

export default Header;

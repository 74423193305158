import React, { useState } from "react";
import Hero from "../../components/hero";
import SEO from "../../components/seo";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { navigate } from "gatsby-link";
import { useStaticQuery } from "gatsby";

// Form Helpers
function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default function Quote({ title }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");

    const [companyName, setCompanyName] = useState("");
    const [sumInsured, setSumInsured] = useState("")
    const [policy, setPolicy] = useState(title)


    const data = useStaticQuery(graphql`
        query {
            corporate: contentfulService (slug: {eq: "corporate-insurance"}) {
                slug
                title
                shortDescription {
                shortDescription
                }
                icon {
                fixed(width: 20, height: 20, quality: 100) {
                    ...GatsbyContentfulFixed
                }
                }
                image {
                    fluid(maxWidth: 1366, quality: 40) {
                    ...GatsbyContentfulFluid
                    }
                }
            }   
            engineering: contentfulService (slug: {eq: "engineering-insurance"}) {
                slug
                title
                shortDescription {
                shortDescription
                }
                icon {
                fixed(width: 20, height: 20, quality: 100) {
                    ...GatsbyContentfulFixed
                }
                }
                image {
                    fluid(maxWidth: 1366, quality: 40) {
                    ...GatsbyContentfulFluid
                    }
                }
            }
            life: contentfulService (slug: {eq: "life-insurance"}) {
                slug
                title
                shortDescription {
                shortDescription
                }
                icon {
                fixed(width: 20, height: 20, quality: 100) {
                    ...GatsbyContentfulFixed
                }
                }
                image {
                    fluid(maxWidth: 1366, quality: 40) {
                    ...GatsbyContentfulFluid
                    }
                }
            }
    }
  `);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": "miscellaneous-insurance-quote",
                name,
                email,
                telephoneNumber: tel,
                companyName,
                sumInsured,
                policy

            }),
        })
            .then(() => navigate(form.getAttribute("action")))
            .catch((error) => alert(error));
    };

    const node = title === "Corporate Insurance" ? data.corporate : title === "Engineering Insurance" ? data.engineering : data.life

    return (
        <>
            <SEO
                title={`${title} Quotation`}
                article={false}
                pathname={`/services/${node.slug}`}
                description={node.shortDescription.shortDescription}
            />
            <Hero
                title={node.title}
                subtitle="Get a Quick Quotation"
                image={node.image.fluid}
            />
            <Container className="my-5 py-5 px-5">
                <h3>Please fill in the form below for your {node.title.toLowerCase()} quotation.</h3>
                <Row className="g-5">
                    <Col md={12}>
                        <Form
                            name="miscellaneous-insurance-quote"
                            method="post"
                            action="/success/"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={handleSubmit}
                        >
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input type="hidden" name="form-name" value="miscellaneous-insurance-quote" />
                            <label style={{ display: "none" }}>
                                Don’t fill this out: <input name="bot-field" />
                            </label>

                            {/* Contact Details */}
                            <Row xs={1} className="mb-5">
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                name="name"
                                                type="text"
                                                placeholder="Full Name"
                                                size="lg"
                                                required
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                            />
                                        </Form.Group></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                placeholder="Your Email"
                                                size="lg"
                                                required
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                name="telephoneNumber"
                                                type="tel"
                                                placeholder="Mobile Number"
                                                size="lg"
                                                required

                                                value={tel}
                                                onChange={(e) => {
                                                    setTel(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Policy</Form.Label>
                                            <Form.Control
                                                name="policy"
                                                type="text"
                                                placeholder="Policy"
                                                size="lg"
                                                disabled
                                                value={policy}
                                                onChange={(e) => {
                                                    setPolicy(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control
                                                name="companyName"
                                                type="text"
                                                placeholder="Company Name"
                                                size="lg"
                                                required
                                                value={companyName}
                                                onChange={(e) => {
                                                    setCompanyName(e.target.value);
                                                }}
                                            />
                                        </Form.Group></Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Sum Insured</Form.Label>
                                            <Form.Control
                                                name="sumInsured"
                                                type="number"
                                                min={0}
                                                placeholder="Sum Insured"
                                                size="lg"
                                                required
                                                value={sumInsured}
                                                onChange={(e) => {
                                                    setSumInsured(e.target.value);
                                                }}
                                            />
                                        </Form.Group></Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Button className="mt-4 mt-lg-5" type="submit" variant="primary2" style={{ width: "100%" }} >
                                            Submit
                                        </Button></Col>
                                </Row>

                            </Row>

                        </Form>
                    </Col>
                </Row >
            </Container >
        </ >
    );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/hero";
import ContactPage from "../components/Contact/contactPage";
import SEO from "../components/seo";

export default function Contact() {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "contact/bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="Contact"
        article={false}
        pathname="/contact"
        description="Contact an insurance comparison expert today! Send us a message or call us on +94 117 514 333."
      />
      <Hero
        title="Contact"
        subtitle="15th Floor, World Trade Center, Sri Lanka"
        image={data.bg.childImageSharp.fluid}
        imagePosition={`50% 0%`}
      />

      <ContactPage />
    </Layout>
  );
}

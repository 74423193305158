import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

import { Container, Row, Col, Button } from "react-bootstrap";

const NotFoundPage = () => {
  return (
    <Layout center>
      <Container className="my-5">
        <Row>
          <Col>
            <h1 className="mb-3 text-capitalize display-5">
              <strong>Page Not Found</strong>
            </h1>
            <p>
              Sorry{" "}
              <span role="img" aria-label="Pensive emoji">
                😔
              </span>{" "}
              we couldn’t find what you were looking for.
            </p>
            <Link to="/">
              <Button variant="primary">Go Home</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Join from "../components/Careers/join";
import SEO from "../components/seo";

export default function Careers() {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "careers/bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="Careers"
        article={false}
        pathname="/careers"
        description="We empower our staff to be agile, collaborative, and trustworthy, giving them a chance to have unique experiences and accomplishments that truly matter."
        keywords="insurance job, job, manager, business"
      />
      <Hero
        title="Careers"
        subtitle="Join Asia Corp Insurance Brokers"
        image={data.bg.childImageSharp.fluid}
        imagePosition={`50% 0%`}
      />
      <Join />
    </Layout>
  );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

// Styles
import styles from "../styles/components/hero.module.scss";

export default function Hero({ title, subtitle, image, imagePosition }) {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className={styles.wrapper}>
      <div className={styles.bg}>
        <Img
          fluid={image || data.bg.childImageSharp.fluid}
          objectFit="cover"
          objectPosition={imagePosition || `50% 50%`}
          style={{ height: "100%" }}
          alt=""
        />
      </div>
      <div className={styles.content}>
        <h1 className="display-2">{title}</h1>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}

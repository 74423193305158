import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  FcSalesPerformance,
  FcPhone,
  FcPositiveDynamic,
  FcMoneyTransfer,
  FcCollaboration,
} from "react-icons/fc";

import styles from "../../styles/components/Homepage/features.module.scss";

const content = [
  {
    icon: <FcPhone />,
    title: "24/7 Customer Support",
    desc: "Call us on +94 117 514 333",
  },
  {
    icon: <FcSalesPerformance />,
    title: "Competitive Rates Guaranteed",
    desc: "Best rates in the market",
  },
  {
    icon: <FcMoneyTransfer />,
    title: "Fast Claim Assistance",
    desc: "Settle your claims within minutes",
  },
  {
    icon: <FcPositiveDynamic />,
    title: "Updates On New Market Trends",
    desc: "Get the latest market updates",
  },
  {
    icon: <FcCollaboration />,
    title: "Tailor Made Insurance Policies",
    desc: "Tailor made insurance policies",
  },
];

export default function Features() {
  return (
    <div className={styles.wrapper}>
      <Container className="pb-2 mt-5 text-center">
        <div className="mt-5 pt-5 mb-5 text-center">
          <h1 className=" text-uppercase">
            <strong>Why Choose Asia Corp?</strong>
          </h1>
          <p>You deserve a chance to save on your insurance!</p>
        </div>
        <Row xs={1} sm={3} md={5} className="my-5 justify-content-center g-5">
          {content.map((item) => (
            <Col
              className={`text-center ${styles.item} mb-5 mb-md-0`}
              key={item.title}
            >
              <div className={`mb-4 display-4 ${styles.icon}`}>{item.icon}</div>
              <p className={`${styles.title} h4`}>
                <strong>{item.title}</strong>
              </p>
              <p className={``}>{item.desc}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Cards from "../components/Services/cards";
import SEO from "../components/seo";

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "services/bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="Insurance Policies"
        article={false}
        pathname="/services"
        description="Assisting you with the best insurance team to help and guide you in getting the most competitive insurance product at the lowest premium!"
        keywords="health insurance, motor insurance, home insurance, corporate insurance, travel insurance, marine insurance, life insurance"
      />
      <Hero
        title="Services"
        subtitle="Comprehensive Insurance Coverage"
        image={data.bg.childImageSharp.fluid}
        imagePosition={`50% 80%`}
      />
      <Cards />
    </Layout>
  );
}

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-events-js": () => import("./../../../src/pages/news&events.js" /* webpackChunkName: "component---src-pages-news-events-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quote-corporate-insurance-js": () => import("./../../../src/pages/quote/corporate-insurance.js" /* webpackChunkName: "component---src-pages-quote-corporate-insurance-js" */),
  "component---src-pages-quote-engineering-insurance-js": () => import("./../../../src/pages/quote/engineering-insurance.js" /* webpackChunkName: "component---src-pages-quote-engineering-insurance-js" */),
  "component---src-pages-quote-health-insurance-js": () => import("./../../../src/pages/quote/health-insurance.js" /* webpackChunkName: "component---src-pages-quote-health-insurance-js" */),
  "component---src-pages-quote-home-insurance-js": () => import("./../../../src/pages/quote/home-insurance.js" /* webpackChunkName: "component---src-pages-quote-home-insurance-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-quote-life-insurance-js": () => import("./../../../src/pages/quote/life-insurance.js" /* webpackChunkName: "component---src-pages-quote-life-insurance-js" */),
  "component---src-pages-quote-marine-insurance-js": () => import("./../../../src/pages/quote/marine-insurance.js" /* webpackChunkName: "component---src-pages-quote-marine-insurance-js" */),
  "component---src-pages-quote-motor-insurance-js": () => import("./../../../src/pages/quote/motor-insurance.js" /* webpackChunkName: "component---src-pages-quote-motor-insurance-js" */),
  "component---src-pages-quote-seven-in-one-insurance-js": () => import("./../../../src/pages/quote/sevenInOne-insurance.js" /* webpackChunkName: "component---src-pages-quote-seven-in-one-insurance-js" */),
  "component---src-pages-quote-travel-insurance-js": () => import("./../../../src/pages/quote/travel-insurance.js" /* webpackChunkName: "component---src-pages-quote-travel-insurance-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-seven-in-one-js": () => import("./../../../src/pages/sevenInOne.js" /* webpackChunkName: "component---src-pages-seven-in-one-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}


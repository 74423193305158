import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import SEO from '../components/seo';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as styles from '../styles/templates/service.module.scss';
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaPhoneAlt,
  FaEnvelope,
} from 'react-icons/fa';
import Img from 'gatsby-image';
const { BLOCKS, MARKS } = require('@contentful/rich-text-types');

//Components

export const query = graphql`
  query($slug: String!) {
    contentfulService(slug: { eq: $slug }) {
      slug
      title
      shortDescription {
        shortDescription
      }
      content {
        json
      }
      icon {
        fixed(width: 20, height: 20, quality: 100) {
          ...GatsbyContentfulFixed
        }
      }
      image {
        fluid(maxWidth: 1366, quality: 40) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default function Service({ data }) {
  const options = {
    //renderNode: {
    //  [BLOCKS.UL_LIST]: (node, children) => {
    //    return <p className="text-primary">{children}</p>;
    //  },
    //},
    renderNode: {
      'embedded-asset-block': (node) => {
        if (!node.data.target.fields) {
          return;
        }
        const alt = node.data.target.fields.title['en-US'];
        const url = node.data.target.fields.file['en-US'].url;
        return (
          <a href={url} target='_blank'>
            <img
              alt={alt}
              src={url}
              className={`${styles.richTextImage} mx-auto my-5`}
            />
          </a>
        );
      },
    },
  };

  const service = data.contentfulService;

  return (
    <Layout>
      <SEO
        title='Asia Corp Insurance Brokers'
        article={false}
        pathname={`/services/${service.slug}`}
        description={service.shortDescription.shortDescription}
      />
      <Hero
        title={service.title}
        subtitle='Asia Corp Insurance Brokers'
        image={service.image.fluid}
      />

      <Container className='my-5 py-5'>
        <Row>
          <Col xl={8}>
            {/* <h1 className={`display-3 ${styles.title} mb-3`}>{service.title}</h1> */}
            <div className={`d-flex align-items-center ${styles.title}`}>
              <h1 className={`display-3 ${styles.title} mb-3`}>
                {service.title}
              </h1>
              <Link to={`/quote/${service.slug}`}>
                <Img
                  fixed={service.icon.fixed}
                  className={`${styles.img} ms-3`}
                />
              </Link>
            </div>
            {/* <Row>
                <Col className=" mb-4 d-flex flex-column flex-md-row">
                    <Link to={`/quote/${service.slug}`}>
                        <Button className="" variant="success">
                            Get A Quotation
                        </Button>
                    </Link>
                    <Link to="/contact">  <Button className="ms-md-2" variant="warning">
                        Send A Message
                    </Button></Link>
                </Col>
              </Row> */}
            <p className={styles.shortDescription}>
              {service.shortDescription.shortDescription}
            </p>
          </Col>
        </Row>
        <hr className='my-5' />
        <Row>
          <Col>
            <div className={styles.content}>
              {service.slug == 'travel-insurance' ? (
                <div className={styles.travelinsurance}>
                  <h1 className={styles.services}>Services</h1>
                  <ul>
                    <li>Loss of Checked-In Baggage</li>
                    <li>Delay of Checked-In Baggage</li>
                    <li>Loss of Passport</li>
                    <li>Trip Cancellation</li>
                    <li>Flight Delay</li>
                    <li>Missed Departure/Connection</li>
                    <li>Hijack Distress</li>
                  </ul>

                  <h1>Easiest Way To Get Insured</h1>
                  <p>
                    Our team at Asia Corp do all the hard work for you, read
                    between the lines, decode the terms and conditions, and make
                    the purchase of an insurance policy for you.
                  </p>
                  <ul>
                    <li>Plans in simple terms</li>
                    <li>Easy and hassle-free online process</li>
                    <li>Unbiased advice</li>
                    <li>Exceptional experience</li>
                  </ul>

                  <h1>Claim Assistance</h1>
                  <p>
                    When you need to file an insurance claim, the last thing you
                    feel like dealing with is a hundred phone transfers and
                    forms that you’re not sure you’re filling out properly. Our
                    expert claims support team at Asia Corp is ready to navigate
                    you through it all. If you need a claim settled, we will
                    assist you with our partner insurers till the rightful
                    judgment is passed. We ensure that all our clients are given
                    top priority.
                  </p>
                  <ul>
                    <li>
                      We are here to listen to your concerns and offer guidance
                      and advice where needed
                    </li>
                    <li>We help you with insurance claims administration</li>
                    <li>
                      Should you experience a complex claim, we can assist with
                      the most feasible solution
                    </li>
                  </ul>

                  <h1>Easiest Way To Get Insured</h1>
                  <p>
                    When you need to file an insurance claim, the last thing you
                    feel like dealing with is a hundred phone transfers and
                    forms that you’re not sure you’re filling out properly. Our
                    expert claims support team at Asia Corp is ready to navigate
                    you through it all. If you need a claim settled, we will
                    assist you with our partner insurers till the rightful
                    judgment is passed. We ensure that all our clients are given
                    top priority.
                  </p>
                  <ul>
                    <li>Get the best quote instantly</li>
                    <li>Compare plans.</li>
                    <li>Save money by selecting the best plan</li>
                  </ul>
                </div>
              ) : service.slug == 'home-insurance' ? (
                // documentToReactComponents(service.content?.json, options)
                <div className={styles.homeinsurance}>
                  <h1 className={styles.services}>Services</h1>
                  <ul>
                    <li>Title Insurance</li>
                    <li>Burglary Insurance</li>
                    <li>Fire Insurance</li>
                    <li>Electronic Equipment</li>
                  </ul>
                </div>
              ) : service.slug == 'life-insurance' ? (
                <div className={styles.lifeinsurance}>
                  <h1>Key Features</h1>
                  <ul>
                    <li>
                      In the event of the untimely death of a breadwinner,
                      provide a lump sum to the family as an income for the
                      family to meet there day to day needs.
                    </li>
                    <li>
                      To cover unexpected medical expenses due to accidents or
                      major illnesses other than normal hospitalization.
                    </li>
                    <li>Provision of a fund for retirement.</li>
                    <li>Provide periodical funds for child education.</li>
                  </ul>

                  <h1>Life Insurance Plans</h1>
                  <ul>
                    <li className={styles.points}>Term Plan:</li>
                    <p>
                      Provide protection for a limited period only, the sum
                      insured of the policy becoming payable if death occurs
                      within a specified period, nothing being payable in the
                      event of survival. Premium of this plan is relatively low
                      when compared to other plans.
                    </p>
                    <li className={styles.points}>Whole Life Plan:</li>
                    <p>
                      This plan is the cheapest method of providing insurance
                      protection for dependents. The sum insured is paid on the
                      death of the life assured. The premium has to pay till the
                      death of the life is assured.
                    </p>
                    <li className={styles.points}>Endowment Plan:</li>
                    <p>
                      This plan combines death protection and the savings aspect
                      of life insurance. Since the sum assured is payable at the
                      expiration of the selected term or the prior death of the
                      life insured.
                    </p>
                    <li className={styles.points}>Life Annuity Plan:</li>
                    <p>
                      This plan provides guaranteed payment at regular intervals
                      as long as the insured is alive or selected period.
                    </p>
                    <li className={styles.points}>
                      Investment-linked Insurance:
                    </li>
                    <p>
                      Life assured can pay premium monthly, quarterly, and
                      yearly. Eligibility age between 18-65 years.
                    </p>
                  </ul>

                  <h4 className='fw-bold'>
                    Following additional covers can be taken with the selected
                    life plans.
                  </h4>
                  <ul>
                    <li>Critical illness cover</li>
                    <li>Hospitalization benefits cover</li>
                    <li>
                      permanent disability benefits due to accidents or
                      sickness.
                    </li>
                    <li>Accidental death cover</li>
                    <li>Spouse cover</li>
                    <li>Funeral expenses cover</li>
                    <li>
                      Whole life cover after maturity of the policy or a
                      selected period
                    </li>
                  </ul>
                </div>
              ) : service.slug == 'motor-insurance' ? (
                <div className={styles.motorinsurance}>
                  <h1 className={styles.services}>Services</h1>
                  <ul>
                    <li>Third-Party Only Motor</li>
                    <li>Comprehensive Motor (Individual and Fleets)</li>
                    <li>Third-Party Fire and Theft Insurance</li>
                    <li>Motor Trade Plate Insurance</li>
                    <li>Special Types of Motor Policies</li>
                  </ul>
                </div>
              ) : service.slug == 'marine-insurance' ? (
                <div className={styles.marineinsurance}>
                  <ul>
                    <li>Freight Forwarders Liability </li>
                    <li>Marine Import & Export Policies </li>
                    <li>Goods in Transit</li>
                    <li>Stock Throughput </li>
                    <li>Hull Machinery Insurance </li>
                    <li>Marine Cargo Import Open Policies Insurance </li>
                    <li>Marine Cargo Export Open Policies Insurance </li>
                    <li>Gem Open Covers </li>
                    <li>P&I Covers </li>
                    <li>Protection and Indemnity (P&I) Covers </li>
                  </ul>
                </div>
              ) : service.slug == 'corporate-insurance' ? (
                <div className={styles.corporateinsurance}>
                  <h1 className={styles.services}>Services</h1>
                  <ul>
                    <li>Director’s and Officer's Liability Insurance</li>
                    <li>Money in Transit Insurance</li>
                    <li>
                      Workmans Compensation/ Employers Liability Insurance
                    </li>
                    <li>Public Liability Insurance</li>
                    <li>Product Liability Insurance</li>
                    <li>All Risks Insurance</li>
                    <li>Fire Insurance</li>
                    <li>Professional Indemnity</li>
                    <li>Personal Accident</li>
                    <li>Fidelity Guarantee</li>
                    <li>Electronic Equipment</li>
                    <li>Cash in Transit</li>
                  </ul>
                </div>
              ) : service.slug == 'engineering-insurance' ? (
                <div className={styles.engineeringinsurance}>
                  <h1 className={styles.services}>Services</h1>
                  <ul>
                    <li>Contractor’s All Risk Policies (CAR)</li>
                    <li>Erection All Risk Policies (EAR)</li>
                    <li>Boiler Insurance</li>
                    <li>Electrical Equipment Cover</li>
                    <li>Machinery Breakdown Insurance</li>
                    <li>Plant and Machinery Insurance</li>
                  </ul>
                </div>
              ) : service.slug == 'health-insurance' ? (
                <div className={styles.healthinsurance}>
                  <h1 className={styles.services}>Services</h1>
                  <ul>
                    <li>Individual/Family Medical Insurance Policies</li>
                    <li>Corporate Group Medical Insurance Policies</li>
                    <li>Global Health Policies</li>
                  </ul>
                </div>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className='mt-3 mb-5 d-flex flex-column flex-md-row'>
            <Link to={`/quote/${service.slug}`}>
              <Button className='m-2'>Get A Quotation</Button>
            </Link>
            <Link to='/contact'>
              {' '}
              <Button className='m-2'>Send A Message</Button>
            </Link>
          </Col>
          <Col xs={12}>
            <p className={`${styles.contact} mb-5`}>
              <FaEnvelope className='me-3' />
              Email Us: <a href='mailto:info@acig.lk'>info@acig.lk</a>
              <br />
              <FaPhoneAlt className='me-3' />
              Hotline: <a href='tel:+94117514333'>+94 117 514 333</a>
            </p>
          </Col>

          <Col>
            <div className={styles.social}>
              <a href='https://www.facebook.com/acig.lk/' target='_blank'>
                <FaFacebook />
              </a>
              <a href='https://www.instagram.com/acig.lk/' target='_blank'>
                <FaInstagram />
              </a>
              <a
                href='https://www.linkedin.com/company/asia-corp-insurance-group/'
                target='_blank'
              >
                <FaLinkedin />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

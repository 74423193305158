import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Logo from "../../utils/logo-xl";

export default function Company() {
  return (
    <Container className="my-5 py-md-5 px-4">
      <Row className="gx-5 justify-content-center align-items-center">
        <Col md={6} className="px-md-5 ">
          <div className="p-2 m-2 p-lg-5 m-lg-5">
            <Logo />
          </div>
        </Col>
        <Col md={6} className="px-md-5 mt-5 mt-md-0">
          <h1 className="mb-3 text-capitalize h1">
            <strong>Company Overview</strong>
          </h1>
          <p>
            ASIA CORP INSURANCE BROKERS (Pvt) Ltd (ACIB) is a leading local
            insurance brokering company registered as a licensed insurance
            broker at the Insurance Regulatory Commission of Sri Lanka (IRCSL)
            and is a member of the Brokering Association. Our corporate
            partnership with Ideal Group, Sri Lanka and Serendib Healthways,
            California - USA, extends our market channel access and business
            growth.
          </p>
          <p>
            As a specialized independent insurance service provider, we have
            strong relationships with all insurers and we are a leading
            Insurance and Reinsurance Broking Company licensed to transact
            General and Life Insurance Business in Sri Lanka and we are also
            registered at the Insurance Regulatory Commission of Sri Lanka
            (IRCSL) and a Member of the Sri Lanka Insurance Brokers Association
            (SLIBA).
          </p>
        </Col>
      </Row>
    </Container>
  );
}

import * as React from 'react';
import Layout from '../../components/layout';
import Quote from '../../components/Quotations/sevenInOneQuote';
import InsuranceProviders from '../../components/insurance-providers';

const IndexPage = () => {
  return (
    <Layout>
      <Quote />
      <InsuranceProviders />
    </Layout>
  );
};

export default IndexPage;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Jumbotron from "./jumbotron";
import { FcApproval } from "react-icons/fc";
import styles from "../../styles/components/Homepage/jumbotron.module.scss";

export default function JumbotronSection() {
  const data = useStaticQuery(graphql`
    query {
      jumbo1: file(relativePath: { eq: "jumbotron/jumbo-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jumbo2: file(relativePath: { eq: "jumbotron/jumbo-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jumbo3: file(relativePath: { eq: "jumbotron/jumbo-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="my-5 ">
      <Jumbotron
        pt0={true}
        variant="rhombusRight"
        image={data.jumbo2.childImageSharp.fluid}
        content={
          <>
            <h1>Easiest Way To Get Insured</h1>
            <p>
              Our team at Asia Corp do all the hard work for you, read between
              the lines, decode the terms and conditions, and make the purchase
              of an insurance policy for you.
            </p>

            <ul className={styles.list}>
              <li>
                <FcApproval className="me-3" />
                Plans explained in simple terms
              </li>
              <li>
                <FcApproval className="me-3" />
                The process is completely online
              </li>
              <li>
                <FcApproval className="me-3" />
                Unbiased advice
              </li>
              <li>
                <FcApproval className="me-3" />
                Exceptional experience
              </li>
            </ul>
            {/* <Link to="/services">
              <Button
                className="justify-self-end mt-3 me-3"
                variant="tertiary3"
                size="lg"
              >
                Get a Quote
              </Button>
            </Link>
            <a href="https://calendly.com/info-36669" target="_blank">
              <Button
                className="justify-self-end mt-3"
                variant="text"
                size="lg"
              >
                Make an Appointment
              </Button>
            </a> */}
          </>
        }
      />

      {/* Section 2 */}
      <Jumbotron
        position="left"
        bg="grey"
        variant="rhombus"
        image={data.jumbo3.childImageSharp.fluid}
        content={
          <>
            <h1>Claim Assistance</h1>
            <p>
              When you need to file an insurance claim, the last thing you feel
              like dealing with is a hundred phone transfers and forms that
              you’re not sure you’re filling out properly.
            </p>

            <p>
              Our expert claims support team at Asia Corp is ready to navigate
              you through it all. If you need a claim settled, we will assist
              you with our partner insurers till the rightful judgement is
              passed. We make sure our customers are taken care of.
            </p>

            <ul className={styles.list}>
              <li>
                <FcApproval className="me-3" />
                We are there to listen to your concerns and offer guidance and
                advice where needed
              </li>
              <li>
                <FcApproval className="me-3" />
                We help you with insurance claims administration
              </li>
              <li>
                <FcApproval className="me-3" />
                Should you experience a complex claim, we can help!
              </li>
            </ul>
          </>
        }
      />

      {/* Section 3 */}
      <Jumbotron
        variant="rhombusRight"
        image={data.jumbo1.childImageSharp.fluid}
        position="right"
        content={
          <>
            <h1>Quick &amp; Hassle Free Buying</h1>
            <p>
              Insurance buying process was never this easy. Get your policy in
              your inbox within minutes &amp; save money while you’re at it! At
              Asia Corp, we demonstrate our commitment to our clients.
            </p>

            <ul className={styles.list}>
              <li>
                <FcApproval className="me-3" />
                Get the best quote instantly
              </li>
              <li>
                <FcApproval className="me-3" />
                Compare plans. Save money by selecting the best plan
              </li>
            </ul>
          </>
        }
      />
    </div>
  );
}

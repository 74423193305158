import React from "react";
// Images
import health from "../images/cards/health.png";
import motor from "../images/cards/motor.png";
import home from "../images/cards/home.png";
import corporate from "../images/cards/corporate.png";
import travel from "../images/cards/travel.png";
import marine from "../images/cards/marine.png";
import {
  GiHouse,
  GiHealthNormal,
  GiJeep,
  GiBriefcase,
  GiShipWheel,
  GiAirplane,
} from "react-icons/gi";

export default [
  {
    title: "Health Insurance",
    link: "https://signnow.com/s/KJ4CMCE0",
    icon: <GiHealthNormal />,
    desc:
      "Protecting your health and income are two essential financial plan elements. Assuring your health is never compromised, our expert team can suggest the best insurance plan for a price that fits your budget with timely claim settlement. ",
    image: health,
    list: [
      "Stress-Free health insurance solutions",
      "Diverse healthcare options",
      "Established partnerships & expertise",
    ],
  },
  {
    title: "Motor Insurance",
    link: "https://signnow.com/s/yn85iSYX",
    icon: <GiJeep />,

    desc:
      "Whether it’s insurance for one car or a fleet of vehicles, we help you get the best cover for your vehicle from financial risks and damages incurred to property and persons. A comprehensive cover for the lowest premium in the market. ",
    image: motor,
    list: ["Private vehicle", "Hiring purpose", "Rent a car"],
  },
  {
    title: "Home Insurance",
    link: "https://signnow.com/s/Mdxehzn3",
    icon: <GiHouse />,
    desc:
      "A comprehensive coverage from multiple disasters or loss and damage due to events such as fire, theft    and storms. We are committed to ensuring the best security for you and the place you call HOME.",
    image: home,
    list: [
      "Damage to your property and possessions",
      "Liability protection for injuries or property damage",
      "Protects your home and its contents against burglaries",
      "Covered against damages that may occur as a result of malicious acts and more",
    ],
  },
  {
    title: "Corporate Insurance",
    link: "https://signnow.com/s/GiiVHfpg",
    icon: <GiBriefcase />,

    desc:
      "For more than 18 years, Asia Corp has been helping businesses with risk management solutions — from commercial property insurance to financial protection to employee benefits that help you focus on propelling your company forward.",
    image: corporate,
    list: [
      "Property and casualty insurance",
      "Business Interruption Insurance",
      "Commercial Auto Insurance",
      "Workers Compensation Insurance",
      "Management Liability Insurance and more",
    ],
  },
  {
    title: "Travel Insurance",
    link: "https://signnow.com/s/K2hoRY62",
    icon: <GiAirplane />,

    desc:
      "Travel insurance policies help to recover unexpected situations that interrupt your travel plans. Many travelers rely on our recommendations to offer the best travel policy keeping your mind at ease to enjoy your travel.",
    image: travel,
    list: [
      "We offer you competitive premiums",
      "International and Domestic business / leisure plans",
      "Comprehensive Policy cover options and more",
    ],
  },
  {
    title: "Marine Insurance",
    link: "https://signnow.com/s/L9h6IqhT",
    icon: <GiShipWheel />,

    desc:
      "We aim to provide information and explain the intricacies involved in marine insurance policies in order to help you choose the best cover. We prepare underwriting reports, negotiate with insurers and understand market risks.",
    image: marine,
    list: [
      "Loss of or damage to any other vessel or property",
      "Removal of wreck",
      "Loss of or damage to any vessel or craft in your care, custody or control and more",
    ],
  },
];

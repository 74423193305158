import React from 'react'
import Calendly from '../components/calendly'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function Appointment () {
  return (
    <Layout>
      <SEO
        title='Appointment'
        description='Book an appointment online with one of our insurance experts.'
        article={false}
        pathname='/appointment'
      />
      <div className='mb-5'>
        <Calendly />
      </div>
    </Layout>
  )
}

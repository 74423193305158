import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import { Container, Row, Col, Button, Accordion, Card } from 'react-bootstrap'
import * as styles from '../styles/templates/service.module.scss'
import Img from 'gatsby-image'

const SevenInOne = () => {
  return (
    <Layout>
      <SEO
        title='Asia Corp Insurance Brokers'
        // article={false}
        // pathname={`/services/${service.slug}`}
        // description={service.shortDescription.shortDescription}
      />
      <Hero
        title='Protecting 7 Major Day to Day Risks'
        subtitle='Asia Corp 7 in 1 Insurance Policy in Partnership with HNB General Insurance Ltd'
        // image={service.image.fluid}
      />
      <Container>
        <div className='my-5 d-flex flex-column flex-xl-row gap-3 mx-auto align-items-lg-start align-items-center '>
          <a href='/brochure-eng.pdf' target='_blank'>
            <Button style={{ whiteSpace: 'nowrap' }}>
              Brochure - English
            </Button>
          </a>
          <a href='/brochure-sin.pdf' target='_blank'>
            <Button style={{ whiteSpace: 'nowrap', margin: '0 auto' }}>
              Brochure - Sinhala
            </Button>
          </a>
          <a href='/brochure-tamil.pdf' target='_blank'>
            <Button style={{ whiteSpace: 'nowrap', margin: '0 auto' }}>
              Brochure - Tamil
            </Button>
          </a>
          <a href='/flyer-eng.pdf' target='_blank'>
            <Button style={{ whiteSpace: 'nowrap' }}>Flyer - English</Button>
          </a>

          <a href='/flyer-sin.pdf' target='_blank'>
            <Button style={{ whiteSpace: 'nowrap' }}>Flyer - Sinhala</Button>
          </a>

          <a href='/flyer-tamil.pdf' target='_blank'>
            <Button style={{ whiteSpace: 'nowrap' }}>Flyer - Tamil</Button>
          </a>
        </div>
        <p className='my-5 d-block'>*Terms and Conditions Apply </p>
      </Container>
      <Container className='mb-5 py-5'>
        <Accordion defaultActiveKey='0'>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey='0' className='py-3'>
              <h2 className='fw-bold text-left'>Exclusive Benefits</h2>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                <Accordion defaultActiveKey='0'>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='0'
                      className='py-3'
                    >
                      <h2 className='fw-bold text-left'>1. Motor Insurance</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='0'>
                      <Card.Body>
                        <p>
                          Comprehensive motor insurance cover including
                          following covers;
                        </p>
                        <ul>
                          <li>Flood & Natural Perils </li>
                          <li>Riot & Strike</li>
                          <li>Terrorism</li>
                          <li>Hire Purchase </li>
                          <li>
                            Personal accident benefit for passengers including
                            driver (LKR 250,000/- each)
                          </li>
                          <li>100% Air Bag Replacement</li>
                          <li>Towing Charges (Up to LKR 10,000/-) </li>
                          <li>No Claim Bonus Protection Cover</li>
                          <li>Special Windscreen Cover (Up to LKR 25,000/-)</li>
                          <li>
                            Damage to Hybrid Battery and All it’s Accessories
                            (Limited to 10% of the sum insured)
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='1'
                      className='py-3'
                    >
                      <h2 className='fw-bold'>
                        2. Home Insurance (Private Dwelling House and contents){' '}
                      </h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='1'>
                      <Card.Body>
                        <p>
                          Protection for insured house and contents from
                          following aspects;
                        </p>
                        <ul>
                          <li>Fire & Lightning</li>
                          <li>Explosion</li>
                          <li>Aircraft Damage </li>
                          <li>Impact Damage </li>
                          <li>Electrical Inclusion</li>
                          <li>Flood</li>
                          <li>Cyclone, Strom & Tempest </li>
                          <li>Natural Perils </li>
                          <li>Bursting & Overflowing of Water Tanks </li>
                          <li>
                            Removal of Debris (Limited LKR 300,000/-or 0.05% of
                            the sum insured, whichever is less){' '}
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='2'
                      className='py-3'
                    >
                      <h2 className='fw-bold'>3. Personal Liability</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='2'>
                      <Card.Body>
                        <ul>
                          <li>Insured legal liability towards third parties</li>
                          <li>
                            Liability is limited to LKR 500,000/- (Per event/ in
                            the aggregate)
                          </li>
                          <li>
                            Coverage operates only within the insured premises
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='3'
                      className='py-3'
                    >
                      <h2 className='fw-bold'>
                        4. Personal Accident (Domestic/ Driver)
                      </h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='3'>
                      <Card.Body>
                        <ul>
                          <li>Accidental death & permanent disability</li>
                          <li>
                            Domestic servant and the driver is eligible for this
                            coverage
                          </li>
                          <li>
                            Benefit is limited to LKR 500,000/- respectively for
                            the domestic servant and the driver
                          </li>
                          <li>
                            Coverage operates within the territorial limits of
                            Sri Lanka
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='4'
                      className='py-3'
                    >
                      <h2 className='fw-bold'>
                        5. Personal Accident + Loan Protection (Insured)
                      </h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='4'>
                      <Card.Body>
                        <p className='fw-bold'>Personal Accident</p>
                        <ul>
                          <li>Accidental death & permanent disability</li>
                          <li>Only insured is eligible for this coverage</li>
                          <li>Benefit is limited to LKR 1,000,000/-</li>
                          <li>Worldwide coverage</li>
                        </ul>
                        <p className='fw-bold'>Loan Protection</p>
                        <ul>
                          <li>
                            Sums will be directly settled to the Financial
                            Institute following to the Accidental death &
                            permanent disability
                          </li>
                          <li>Benefit is limited to LKR 1,000,000/-</li>
                          <li>Worldwide coverage</li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='5'
                      className='py-3'
                    >
                      <h2 className='fw-bold'>6. Pet Insurance</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='5'>
                      <Card.Body>
                        <ul>
                          <li>
                            Cost incurred for treatments of your pet dog or cat
                            from registered veterinary practitioner including
                            prescribe medicines up to LKR 10,000/-{' '}
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='6'
                      className='py-3'
                    >
                      <h2 className='fw-bold'>7. Hospital Expenses</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='6'>
                      <Card.Body>
                        <ul>
                          <li>
                            Reimbursement of hospital expenses for treatments
                            being given whilst inmate as a patient up to LKR
                            50,000/-
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>

      {/* FEATURES */}
      <Container className='mb-5 pb-5'>
        <Accordion defaultActiveKey='0'>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey='0' className='py-3'>
              <h2 className='fw-bold'>Features</h2>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                <ul>
                  <li>All seven covers under one umbrella</li>
                  <li>
                    Single proposal form to be filled with less information
                  </li>
                  <li>
                    Fully loaded package covering all aspects of insured
                    including valuable assets, liability, life, hospital
                    expenses and pets being under care
                  </li>
                  <li>Guaranteed mind free assurance plan</li>
                  <li>Guaranteed low premium</li>
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>

      {/* POLICY ELIGIBILITY */}
      <Container className='mb-5 pb-5'>
        <Accordion defaultActiveKey='0'>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey='0' className='py-3'>
              <h2 className='fw-bold'>Policy Eligibility</h2>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                <ol>
                  <li>Name of the proposer</li>
                  <li>Date of birth </li>
                  <li>Vehicle make, model and year of make</li>
                  <li>
                    Vehicle value (Value should be in line with the current
                    market price)
                  </li>
                  <li>Value of the building and risk location (House)</li>
                </ol>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>

      {/* How do I obtain this policy? [dropdown]  */}
      <Container className='mb-5 pb-5'>
        <Accordion defaultActiveKey='0'>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey='0' className='py-3'>
              <h2 className='fw-bold'>How do I obtain this policy?</h2>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                <div className='pb-3'>
                  <p className='fw-bold'>STEP 1</p>
                  <p>
                    Submit your details (as mentioned under Policy Eligibility)
                    to <a href='mailto:technical@acig.lk'>technical@acig.lk</a>{' '}
                    , call <a href='tel:+94 117 514 333'>+94 117 514 333</a> or
                    WhatsApp{' '}
                    <a href='https://wa.me/94761408437' target='_blank'>
                      +94 761 408 437
                    </a>
                  </p>
                </div>
                <div className='pb-3'>
                  <p className='fw-bold'>STEP 2</p>
                  <p>
                    Obtain your quotation and discuss the terms with our
                    dedicated team
                  </p>
                </div>
                <div className='pb-3'>
                  <p className='fw-bold'>STEP 3</p>
                  <p>Proposal form signing to confirm your policy</p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
      {/* POLICY ELIGIBILITY */}
      <Container className='mb-5 pb-5'>
        <Accordion defaultActiveKey='0'>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey='0' className='py-3'>
              <h2 className='fw-bold'>Contact & Support</h2>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                <p>
                  Email -{' '}
                  <a href='mailto:technical@acig.lk'>technical@acig.lk</a>
                </p>
                <p>
                  Call – <a href='tel:+94 117 514 333'>+94 117 514 333</a>
                </p>
                <p>
                  WhatsApp -{' '}
                  <a href='https://wa.me/94761408437' target='_blank'>
                    +94 761 408 437
                  </a>
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    </Layout>
  )
}

export default SevenInOne

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Fonts
import "@fontsource/montserrat";
import "@fontsource/poppins";
import "@fontsource/alex-brush";

// React Multi Carousel
import "react-multi-carousel/lib/styles.css";

// Custom Styles
import "./src/styles/global.scss";

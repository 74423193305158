import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Mission from "../components/About/mission";
import Company from "../components/About/company";
import Hero from "../components/hero";
import CEO from "../components/About/ceo";
import Partners from "../components/About/partners";
import SEO from "../components/seo";

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "about/bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="About"
        article={false}
        pathname="/about"
        description="Licensed under the Insurance Regulatory Commission of Sri Lanka (IRCSL) and member of the Sri Lanka Insurance Brokers Association (SLIBA)."
      />
      <Hero
        title="Who We Are"
        subtitle="Asia Corp Insurance Brokers"
        image={data.bg.childImageSharp.fluid}
        imagePosition={`50% 0%`}
      />
      <Company />
      <CEO />
      <Mission />
      <Partners />
      <div className="pb-5" />
    </Layout>
  );
}

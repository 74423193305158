import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { Button, Col, Container } from 'react-bootstrap';
import { FaArrowCircleRight } from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';

import styles from '../../styles/components/Homepage/carousel.module.scss';
import bgImg from '../../images/bg-3.png';
import sevenInOne from '../../images/7in1.png';
import sevenInOneIcons from '../../images/7in1-icons.png';
import sevenInOneLogos from '../../images/7in1-logos.png';

export default function LandingCarousel() {
  const data = useStaticQuery(graphql`
    query {
      bg1: file(relativePath: { eq: "bg-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bg2: file(relativePath: { eq: "bg-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bg3: file(relativePath: { eq: "bg-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgIcons: file(relativePath: { eq: "7in1-icons.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img7In1: file(relativePath: { eq: "7in1.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const images = [
    {
      image: data.bg3.childImageSharp.fluid,
      position: '50% 35%',
      title: 'Asia Corp 7 in 1',
      subtitle: 'Complete protection with one Insurance cover',
      button1: 'View Policy',
      button1Link: '/sevenInOne',
      button2: 'Get a Quote',
      button2Link: '/quote/sevenInOne-insurance',
      imgIcons: data.imgIcons.childImageSharp.fluid,
      img7In1: data.img7In1.childImageSharp.fluid,
    },
    {
      image: data.bg2.childImageSharp.fluid,
      position: '50% 48%',
      title: 'The Insurance Savings Expert',
      subtitle: 'Quick & Hassle Free Insurance Comparison',
      button1: 'Book an Appointment',
      button1Link: '/appointment',
      button2: 'Get a Quote',
      button2Link: '/services',
    },
    {
      image: data.bg1.childImageSharp.fluid,
      position: '50% 45%',
      title: 'The Insurance Savings Expert',
      subtitle: 'Quick & Hassle Free Insurance Comparison',
      button1: 'Book an Appointment',
      button1Link: '/appointment',
      button2: 'Get a Quote',
      button2Link: '/services',
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const activeImage = images[activeIndex];
  console.log(activeIndex);

  return (
    <Container fluid className={`p-0 m-0 ${styles.container}`}>
      <Col md={6} lg={6} className={styles.content}>
        <h1 className={`display-1`}>{activeImage.title}</h1>
        <p className={styles.subtitle}>{activeImage.subtitle}</p>
        <div className='d-lg-flex mt-4 justify-content-center'>
          <Link to={activeImage.button1Link}>
            <Button variant='primary'>{activeImage.button1}</Button>
          </Link>

          <Link to={activeImage.button2Link} style={{ textDecoration: 'none' }}>
            <Button
              variant='text'
              className='d-flex align-items-center mt-3 mt-lg-0 ms-lg-4 m-auto m-lg-0 justify-content-center'
              style={{ border: 'none' }}
            >
              {activeImage.button2}
              <FaArrowCircleRight className='ms-3' />
            </Button>
          </Link>
        </div>
      </Col>

      <Carousel
        swipeable={true}
        showThumbs={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        interval={5000}
        onChange={(index) => setActiveIndex(index)}
      >
        {images.map((image, index) => (
          <div key={index} className={styles.image}>
            {activeIndex === 0 ? (
              <div style={{ position: 'relative', height: '100%' }}>
                {/* Background Image */}
                <Img
                  fluid={image.image}
                  objectFit='cover'
                  objectPosition={image.position || '50% 50%'}
                  alt=''
                  style={{ height: '100%' }}
                />
                {/* 7in1 Image */}
                <img src={sevenInOne} className={styles.sevenInOneImg} />
                <img src={sevenInOneIcons} className={styles.sevenInOneIcons} />
                <img src={sevenInOneLogos} className={styles.sevenInOneLogos} />
              </div>
            ) : (
              <Img
                fluid={image.image}
                objectFit='cover'
                objectPosition={image.position || '50% 50%'}
                alt=''
                style={{ height: '100%' }}
              />
            )}
          </div>
        ))}
      </Carousel>
    </Container>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import Drawer from '@material-ui/core/Drawer';
import Logo from '../utils/logo';
import {
  FaHome,
  FaInfo,
  FaDochub,
  FaBriefcase,
  FaEnvelope,
  FaUserClock,
  FaMinus,
  FaPlus,
  FaNewspaper,
} from 'react-icons/fa';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import NavdrawerLinks from './navdrawerLinks';

const styles = {
  list: {
    width: 250,
  },
  paper: {
    backgroundImage: 'linear-gradient(#23395b 0%, #161925 100%)',
    top: 0,
  },
};

class TemporaryDrawer extends React.Component {
  state = {
    left: false,
    open: false,
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { classes } = this.props;
    const sideList = (
      <div className='navdrawer'>
        <div className='navdrawer__logo'>
          <Logo />
        </div>
        <div className={classes.list}>
          <List>
            <Link to='/'>
              <ListItem button>
                <h3 className='navdrawer__link'>
                  <span>
                    <FaHome />
                  </span>
                  Home
                </h3>
              </ListItem>
            </Link>

            <Link to='/about'>
              <ListItem button>
                <h3 className='navdrawer__link'>
                  <span>
                    <FaInfo />
                  </span>
                  About
                </h3>
              </ListItem>
            </Link>

            <ListItem button onClick={this.handleClick}>
              <h3 className='navdrawer__link'>
                <span>
                  <FaDochub />
                </span>
                Services
                <span className='ms-2'>
                  {this.state.open ? <FaMinus /> : <FaPlus />}
                </span>
              </h3>
            </ListItem>
            <Collapse in={this.state.open} timeout='auto' unmountOnExit>
              <NavdrawerLinks />
            </Collapse>

            <Link to='/careers'>
              <ListItem button>
                <h3 className='navdrawer__link'>
                  <span>
                    <FaBriefcase />
                  </span>
                  Careers
                </h3>
              </ListItem>
            </Link>

            <Link to='/news&events'>
              <ListItem button>
                <h3 className='navdrawer__link'>
                  <span>
                    <FaNewspaper />
                  </span>
                  News & Events
                </h3>
              </ListItem>
            </Link>

            <Link to='/contact'>
              <ListItem button>
                <h3 className='navdrawer__link'>
                  <span>
                    <FaEnvelope />
                  </span>
                  Contact
                </h3>
              </ListItem>
            </Link>

            <a
              href='https://calendly.com/info-asiacorpinsurancebrokers'
              target='_blank'
            >
              <ListItem button>
                <h3 className='navdrawer__link'>
                  <span>
                    <FaUserClock />
                  </span>
                  Book Appointment
                </h3>
              </ListItem>
            </a>
          </List>
        </div>
      </div>
    );

    return (
      <div>
        <div
          id='nav-icon'
          className={this.state.right ? 'open' : 'close'}
          onClick={this.toggleDrawer('right', true)}
        >
          <span />
          <span />
          <span />
        </div>
        <Drawer
          classes={{ paper: classes.paper }}
          anchor='left'
          open={this.state.right}
          onClose={this.toggleDrawer('right', false)}
        >
          <div
            tabIndex={0}
            role='button'
            // onClick={this.toggleDrawer("right", false)}
            onKeyDown={this.toggleDrawer('right', false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TemporaryDrawer);

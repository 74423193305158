import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Quote from '../../components/Quotations/home'
import InsuranceProviders from "../../components/insurance-providers";

const IndexPage = () => {
    return (
        <Layout>
            <Quote />
            <InsuranceProviders />
        </Layout>
    );
};

export default IndexPage;

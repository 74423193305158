import React from "react";
import { Jumbotron, Container, Col, Row } from "react-bootstrap";
import Img from "gatsby-image";
import styles from "../../styles/components/Homepage/jumbotron.module.scss";
import { Link } from 'gatsby'

export default function JumbotronComponent({
  position,
  svg,
  image,
  content,
  variant,
  mb0,
  pt0,
  bg,
  link = ""
}) {
  return (
    <Jumbotron className={`${bg === "grey" ? styles.bg : ""}`}>
      <Container className={`py-5`}>
        <Row
          className={`py-5 px-2 mx-md-5 gx-5 align-items-center justify-content-evenly ${position ===
            "left" && "flex-row-reverse"}
            ${mb0 && "mb-0"} 
            ${pt0 && "pt-0"} 
            `}
        >
          <Col md={5} className="mb-5 mb-lg-0 ">
            {svg && (
              <div className={`${styles.svg}`}>
                <img src={svg} />
              </div>
            )}
            <Link to={link}>
              <Img
                fluid={image}
                className={`mb-2 mb-md-0 ${variant === "rhombus"
                  ? styles.rhombus
                  : variant === "rhombusRight"
                    ? styles.rhombusRight
                    : ""
                  } ${position === "left" ? styles.left : styles.right}`}
              />
            </Link>

          </Col>
          <Col md={7} className={`${position === "left" ? "" : "ps-lg-5"}`}>
            {content}
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import { Container, Row, Col } from "react-bootstrap";

export default function Partners() {
  const data = useStaticQuery(graphql`
    query {
      ircsl: file(relativePath: { eq: "IRCSL-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliba: file(relativePath: { eq: "SLIBA-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ideal: file(relativePath: { eq: "ideal-motors.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      serendib: file(relativePath: { eq: "serendib-healthways.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container className="text-center">
      <Row className={` my-5  justify-content-center gx-5`}>
        <Col sm={6} md={4} className={`px-5 mb-5 mb-md-0`}>
          <h5 className={`mb-5`}>
            <strong>Registered Under</strong>
          </h5>
          <Row className="align-items-center">
            <Col xs={7}>
              <a href="https://ircsl.gov.lk/" target="_blank">
                <Img
                  fluid={data.ircsl.childImageSharp.fluid}
                  objectFit="contain"
                  alt="IRCSL"
                  style={{
                    maxHeight: "65px",
                    maxWidth: "200px",
                    margin: "auto",
                  }}
                />
              </a>
            </Col>
            <Col xs={5}>
              <a href="http://www.sliba.lk/" target="_blank">
                <Img
                  fluid={data.sliba.childImageSharp.fluid}
                  objectFit="contain"
                  alt="IRCSL"
                  style={{
                    maxHeight: "65px",
                    maxWidth: "200px",
                    margin: "auto",
                  }}
                />
              </a>
            </Col>
          </Row>
        </Col>

        <Col sm={6} md={4} className={`px-5 mb-5 mb-md-0`}>
          <h5 className={`mb-5 `}>
            <strong>Partners</strong>
          </h5>
          <Row className="align-items-center">
            <Col xs={6} className="text-center">
              <a href="https://idealmotors.lk/" target="_blank">
                <Img
                  fluid={data.ideal.childImageSharp.fluid}
                  objectFit="contain"
                  alt="Ideal Group Logo"
                  className="m-auto"
                  style={{
                    maxHeight: "65px",
                    maxWidth: "200px",
                    alignSelf: "center",
                  }}
                />
              </a>
            </Col>
            <Col xs={6} className="text-center">
              <a href="https://www.serendibhealthways.com/" target="_blank">
                <Img
                  fluid={data.serendib.childImageSharp.fluid}
                  objectFit="contain"
                  alt="Ideal Group Logo"
                  className="m-auto"
                  style={{
                    maxHeight: "65px",
                    maxWidth: "200px",
                    alignSelf: "center",
                  }}
                />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

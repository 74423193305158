import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Carousel from "../components/Homepage/carousel";
import Features from "../components/Homepage/features";
import Cards from "../components/Homepage/cards";
import JumbotronSection from "../components/Homepage/jumbotronSection";
import Partners from "../components/Homepage/partners";
import InsuranceProviders from "../components/insurance-providers";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Asia Corp Insurance Brokers"
        article={false}
        pathname="/"
        description="The insurance comparison expert. Assisting you in finding the most competitive insurance at the lowest premium!"
      />
      <Carousel />
      <Partners />

      <Cards />

      <Features />

      <JumbotronSection />
      <InsuranceProviders />
    </Layout>
  );
};

export default IndexPage;

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
    FaDochub,
} from 'react-icons/fa'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';


export default function NavdrawerLinks() {
    const data = useStaticQuery(graphql`
    query {
      allContentfulService {
        edges {
          node {
              title
              slug
            }
          }
        }
    }
  `)
    return (
        <List component="div">
            {data.allContentfulService.edges.map(({ node }) => (
                <Link to={`/services/${node.slug}`}>
                    <ListItem button className="me-4" >
                        <h5 className="navdrawer__link small">
                            {node.title}
                        </h5>
                    </ListItem></Link>
            ))}
        </List>
    )
}

import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

import styles from '../styles/components/infobar.module.scss';

export default function Infobar() {
  return (
    <div className={`${styles.main} d-none d-md-block`}>
      <Container>
        <Col className='d-flex align-items-center py-3'>
          <a href='mailto:info@acig.lk' className={`h4 me-5 ${styles.link}`}>
            <FaEnvelope className='me-3' />
            info@acig.lk
          </a>

          <a
            href='tel:+94 117 514 333'
            className={`h4 ${styles.link} ${styles.border}`}
          >
            <FaPhone className='me-3 ' />
            Hotline: +94 117 514 333
          </a>
        </Col>
      </Container>
    </div>
  );
}

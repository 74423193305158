import React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";

import styles from "../styles/components/layout.module.scss";

const Layout = ({ children, center }) => {
  return (
    <main>
      <Header />
      <div className={styles.layout}>
        <div className={center ? styles.center : {}}>{children}</div>
      </div>
      <Footer />
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

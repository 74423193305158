import * as React from "react";
import Layout from "../../components/layout";
import Quote from '../../components/Quotations/other'
import InsuranceProviders from "../../components/insurance-providers";

const IndexPage = () => {
    return (
        <Layout>
            <Quote title="Life Insurance" />
            <InsuranceProviders />
        </Layout>
    );
};

export default IndexPage;

import React, { useState } from "react";
import Hero from "../../components/hero";
import SEO from "../../components/seo";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { navigate } from "gatsby-link";
import { useStaticQuery } from "gatsby";
import Contact from './contact'

// Form Helpers
function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default function Quote() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");

    // Travel
    const [country, setCountry] = useState("")
    const [dateTo, setDateTo] = useState("")
    const [dateFrom, setDateFrom] = useState("")
    const [passengers, setPassengers] = useState(1)


    // Insured Information
    const [name1, setName1] = useState("")
    const [relationship1, setRelationship1] = useState("")
    const [passport1, setPassport1] = useState("")
    const [dob1, setDob1] = useState("")

    const [name2, setName2] = useState("")
    const [relationship2, setRelationship2] = useState("")
    const [passport2, setPassport2] = useState("")
    const [dob2, setDob2] = useState("")

    const [name3, setName3] = useState("")
    const [relationship3, setRelationship3] = useState("")
    const [passport3, setPassport3] = useState("")
    const [dob3, setDob3] = useState("")

    const [name4, setName4] = useState("")
    const [relationship4, setRelationship4] = useState("")
    const [passport4, setPassport4] = useState("")
    const [dob4, setDob4] = useState("")

    const data = useStaticQuery(graphql`
        query {
        contentfulService (slug: {eq: "travel-insurance"}) {
            slug
            title
            shortDescription {
            shortDescription
            }
            icon {
              fixed(width: 20, height: 20, quality: 100) {
                ...GatsbyContentfulFixed
              }
            }
            image {
        fluid(maxWidth: 1366, quality: 40) {
            ...GatsbyContentfulFluid
        }
      }
        }
    }
  `);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": "travel-insurance-quote",
                name,
                email,
                telephoneNumber: tel,
                country,
                "To Date": dateTo,
                "From Date": dateFrom,
                passengers,
                name1,
                relationship1,
                passport1,
                dob1,
                name2,
                relationship2,
                passport2,
                dob2,
                name3,
                relationship3,
                passport3,
                dob3,
                name4,
                relationship4,
                passport4,
                dob4,
            }),
        })
            .then(() => navigate(form.getAttribute("action")))
            .catch((error) => alert(error));
    };

    return (
        <>
            <SEO
                title={`${data.contentfulService.title} Quotation`}
                article={false}
                pathname={`/services/${data.contentfulService.slug}`}
                description={data.contentfulService.shortDescription.shortDescription}
            />
            <Hero
                title={data.contentfulService.title}
                subtitle="Get a Quick Quotation"
                image={data.contentfulService.image.fluid}
            />
            <Container className="my-5 py-5 px-5">
                <h3>Please fill in the form below for your {data.contentfulService.title.toLowerCase()} quotation.</h3>
                <Row className="g-5 ">
                    <Col md={12}>
                        <Form
                            name="travel-insurance-quote"
                            method="post"
                            action="/success/"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={handleSubmit}
                        >
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input type="hidden" name="form-name" value="travel-insurance-quote" />
                            <label style={{ display: "none" }}>
                                Don’t fill this out: <input name="bot-field" />
                            </label>

                            {/* Contact Details */}

                            <Row xs={1} className="mb-5">
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                name="name"
                                                type="text"
                                                placeholder="Full Name"
                                                size="lg"
                                                required
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value);
                                                }}
                                            />
                                        </Form.Group></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                placeholder="Your Email"
                                                size="lg"
                                                required
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                name="telephoneNumber"
                                                type="tel"
                                                placeholder="Mobile Number"
                                                size="lg"
                                                required

                                                value={tel}
                                                onChange={(e) => {
                                                    setTel(e.target.value);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* <Form.Group className="mb-5">
                            <Form.Label>Insurance Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="insuranceType"
                                size="lg"
                                required
                                value={insuranceType}
                                onChange={(e) => {
                                    setInsuranceType(e.target.value);
                                }}
                            >
                                <option>Home Insurance</option>
                                <option>Life Insurance</option>
                            </Form.Control>
                        </Form.Group> */}


                                {/* Travel Insurance */}
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Visiting Country/Countries</Form.Label>
                                            <Form.Control
                                                name="country"
                                                type="text"
                                                placeholder="Visiting Country"
                                                size="lg"
                                                required
                                                value={country}
                                                onChange={(e) => {
                                                    setCountry(e.target.value);
                                                }}
                                            />
                                        </Form.Group></Col>
                                </Row>



                                <Form.Label className="mt-5 mb-0">Period of Cover</Form.Label>
                                <Row className="mb-5 g-3" xs={1} sm={2} lg={4}>
                                    <Col>
                                        <Form.Label>From</Form.Label>
                                        <Form.Control
                                            name="fromDate"
                                            type="date"
                                            placeholder="From"
                                            size="lg"
                                            required
                                            value={dateFrom}
                                            onChange={(e) => {
                                                setDateFrom(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>To</Form.Label>
                                        <Form.Control
                                            name="toDate"
                                            type="date"
                                            placeholder="To"
                                            size="lg"
                                            required
                                            value={dateTo}
                                            onChange={(e) => {
                                                setDateTo(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row className="my-3 mb-2 g-3" xs={1} lg={4}>
                                    <Col className="mb-4">
                                        <Form.Label>No. of Passengers</Form.Label>
                                        <Form.Control
                                            name="passengers"
                                            type="number"
                                            placeholder="No. of Passengers"
                                            size="lg"
                                            min={1}
                                            max={4}
                                            required
                                            value={passengers}
                                            onChange={(e) => {
                                                setPassengers(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>


                                <Row>
                                    <Col>
                                        <Row className={`g-3 `} xs={1} sm={2} md={4} >
                                            <Col>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    name="name1"
                                                    type="text"
                                                    placeholder="Name"
                                                    size="lg"
                                                    required
                                                    value={name1}
                                                    onChange={(e) => {
                                                        setName1(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label>Relationship</Form.Label>
                                                <Form.Control
                                                    name="relationship1"
                                                    type="text"
                                                    placeholder="Relationship"
                                                    size="lg"
                                                    required
                                                    value={relationship1}
                                                    onChange={(e) => {
                                                        setRelationship1(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label>Passport No.</Form.Label>
                                                <Form.Control
                                                    name="passport1"
                                                    type="text"
                                                    placeholder="Passport No."
                                                    size="lg"
                                                    required
                                                    value={passport1}
                                                    onChange={(e) => {
                                                        setPassport1(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label>Date of Birth</Form.Label>
                                                <Form.Control
                                                    name="dob1"
                                                    type="date"
                                                    placeholder="To"
                                                    size="lg"
                                                    required
                                                    value={dob1}
                                                    onChange={(e) => {
                                                        setDob1(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        {/* 2 */}
                                        {passengers > 1 && (
                                            <Row className="my-5" xs={1} sm={2} md={4}>
                                                <Col>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        name="name2"
                                                        type="text"
                                                        placeholder="Name"
                                                        size="lg"
                                                        required
                                                        value={name2}
                                                        onChange={(e) => {
                                                            setName2(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Relationship</Form.Label>
                                                    <Form.Control
                                                        name="relationship2"
                                                        type="text"
                                                        placeholder="Relationship"
                                                        size="lg"
                                                        required
                                                        value={relationship2}
                                                        onChange={(e) => {
                                                            setRelationship2(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Passport No</Form.Label>
                                                    <Form.Control
                                                        name="passport2"
                                                        type="text"
                                                        placeholder="Passport No"
                                                        size="lg"
                                                        required
                                                        value={passport2}
                                                        onChange={(e) => {
                                                            setPassport2(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Date of Birth</Form.Label>
                                                    <Form.Control
                                                        name="dob2"
                                                        type="date"
                                                        placeholder="To"
                                                        size="lg"
                                                        required
                                                        value={dob2}
                                                        onChange={(e) => {
                                                            setDob2(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        )}

                                        {/* 3 */}
                                        {passengers > 2 && (
                                            <Row className="my-5" xs={1} sm={2} md={4}>
                                                <Col>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        name="name3"
                                                        type="text"
                                                        placeholder="Name"
                                                        size="lg"
                                                        required
                                                        value={name3}
                                                        onChange={(e) => {
                                                            setName3(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Relationship</Form.Label>
                                                    <Form.Control
                                                        name="relationship3"
                                                        type="text"
                                                        placeholder="Relationship"
                                                        size="lg"
                                                        required
                                                        value={relationship3}
                                                        onChange={(e) => {
                                                            setRelationship3(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Passport No</Form.Label>
                                                    <Form.Control
                                                        name="passport3"
                                                        type="text"
                                                        placeholder="Passport No"
                                                        size="lg"
                                                        required
                                                        value={passport3}
                                                        onChange={(e) => {
                                                            setPassport3(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Date of Birth</Form.Label>
                                                    <Form.Control
                                                        name="dob3"
                                                        type="date"
                                                        placeholder="To"
                                                        size="lg"
                                                        required
                                                        value={dob3}
                                                        onChange={(e) => {
                                                            setDob3(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        )}

                                        {/* 4 */}
                                        {passengers > 3 && (
                                            <Row className="my-5" xs={1} sm={2} md={4}>
                                                <Col>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control
                                                        name="name4"
                                                        type="text"
                                                        placeholder="Name"
                                                        size="lg"
                                                        required
                                                        value={name4}
                                                        onChange={(e) => {
                                                            setName4(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Relationship</Form.Label>
                                                    <Form.Control
                                                        name="relationship4"
                                                        type="text"
                                                        placeholder="Relationship"
                                                        size="lg"
                                                        required
                                                        value={relationship4}
                                                        onChange={(e) => {
                                                            setRelationship4(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Passport No</Form.Label>
                                                    <Form.Control
                                                        name="passport4"
                                                        type="text"
                                                        placeholder="Passport No"
                                                        size="lg"
                                                        required
                                                        value={passport4}
                                                        onChange={(e) => {
                                                            setPassport4(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Date of Birth</Form.Label>
                                                    <Form.Control
                                                        name="dob4"
                                                        type="date"
                                                        placeholder="To"
                                                        size="lg"
                                                        required
                                                        value={dob4}
                                                        onChange={(e) => {
                                                            setDob4(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Button className="mt-4 mt-lg-5" type="submit" variant="primary2" style={{ width: "100%" }} >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Row>

                        </Form>
                    </Col>
                </Row>

                {/* <Row className="mt-5 pt-5">
                    <Col md={6}>
                        <Contact />
                    </Col>

                    <Col md={6} >
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31684.830974611366!2d79.82612709714508!3d6.937855879032204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2592655555555%3A0xa7bd3c85394bb7ad!2sASIA%20CORP%20HOLDINGS%20(PVT)%20LTD!5e0!3m2!1sen!2slk!4v1646901201837!5m2!1sen!2slk"
                            width="600"
                            height="400"
                            style={{ border: 0, maxWidth: "100%" }}
                            allowFullscreen=""
                            loading="lazy"
                            className="mb-3"
                        ></iframe>
                    </Col>
                </Row> */}
            </Container >
        </ >
    );
}

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Cards from '../components/news&events/cards';
import SEO from '../components/seo';
import { Card } from 'react-bootstrap';

export default function NewsAndEvents() {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "acig-news-7in1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title='News & Events'
        article={false}
        pathname='/news&events'
        description='News articles, events, and latest updates about ACIG'
      />
      <Hero
        title='News & Events'
        image={data.bg.childImageSharp.fluid}
        imagePosition={`50% 0%`}
      />

      <Cards />
    </Layout>
  );
}

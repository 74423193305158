import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

export default function Contact() {
  return (
    <>
      <div className='mb-5'>
        <h1 className='mb-4'>
          <strong>Questions?</strong>
        </h1>
        <p>
          Asia Corp Insurance Brokers (Pvt) Ltd is an established organization
          in the industry with all requisites to secure the insurance broker
          license from the Insurance Regulatory Commission of Sri Lanka (IRCSL)
          and a member of the Sri Lanka Insurance Brokers Association (SLIBA).
        </p>
      </div>

      <div className='mb-5'>
        <h3 className='mb-4'>
          <strong>Office Location</strong>
        </h3>
        <p>
          15th Floor, East Tower <br /> World Trade Center <br /> Colombo 01,
          Sri Lanka
        </p>
      </div>

      <div className='mb-5'>
        <h3 className='mb-4'>
          <strong>Contact Information</strong>
        </h3>
        <p className='mb-0'>
          <strong> Email: </strong>
          <a href='mailto:info@acig.lk'>info@acig.lk</a>
        </p>
        <p>
          <strong> Hotline: </strong>
          <a href='tel:+94117514333'>+94-117-514-333</a>
        </p>
      </div>
    </>
  );
}
